import React from 'react'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    errorText?: string
}

type PQTextArea = React.FC<Props>

const TextArea: PQTextArea = ({ errorText, ...props }) => {
    return <React.Fragment>
        <textarea {...props} className={`pq-textarea ${errorText && errorText !== '' ? 'border-orange' : ''} ${props.className ? props.className : ''}`} />
        <span className='d-inline-block text-orange fs-12px'>{errorText}</span>
    </React.Fragment>
}

export default TextArea