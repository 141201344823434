import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

type PQSectionHeading = React.FC<Props>

const SectionHeading: PQSectionHeading = (props) => {
    const { width } = useDimension()

    return <div {...props} className={`text-uppercase ff-dax-pro text-orange mb-40px text-center fw-regular ${width < 768 ? 'fs-14px' : 'fs-22px'} ${props.className ? props.className : ''}`} />
}

export default SectionHeading