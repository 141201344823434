import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQContactCard = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '42',
    height: '42',
    viewBox: '0 0 42 42',
}

const ContactCard: PQContactCard = (props: Props) => {
    return <svg {...props} className={`pq-contact-card ${props.className ? props.className : ''}`}>
        <g id='Group_3668' data-name='Group 3668' transform='translate(-504 -1665)'>
            <g id='Group_3667' data-name='Group 3667' transform='translate(513.257 1670.651)'>
                <path id='Path_7591' data-name='Path 7591' d='M23.078,5H.744A.744.744,0,0,0,0,5.744V24.356a.744.744,0,0,0,.744.744H23.078a.744.744,0,0,0,.744-.744V5.744A.744.744,0,0,0,23.078,5Zm-.744,1.489V9.467H1.489V6.489ZM1.489,23.612V10.956H22.334V23.612Z' transform='translate(0 0)' fill='#ff725e' />
                <path id='Path_7592' data-name='Path 7592' d='M8.745,12.505a.75.75,0,0,0,.744-.744.753.753,0,0,0-.22-.529.771.771,0,0,0-1.053,0,.744.744,0,0,0,.529,1.269Z' transform='translate(-5.023 -3.783)' fill='#ff725e' />
                <path id='Path_7593' data-name='Path 7593' d='M14.744,12.505a.745.745,0,0,0,.525-1.273.771.771,0,0,0-1.053,0,.744.744,0,0,0,.529,1.269Z' transform='translate(-8.789 -3.783)' fill='#ff725e' />
                <path id='Path_7594' data-name='Path 7594' d='M20.744,12.505a.75.75,0,0,0,.744-.744.753.753,0,0,0-.22-.529.771.771,0,0,0-1.053,0,.75.75,0,0,0-.216.525.75.75,0,0,0,.744.744Z' transform='translate(-12.555 -3.783)' fill='#ff725e' />
                <path id='Path_7595' data-name='Path 7595' d='M16.561,25H8.744a.744.744,0,1,0,0,1.489h7.817a.744.744,0,1,0,0-1.489Z' transform='translate(-5.022 -12.555)' fill='#ff725e' />
                <path id='Path_7596' data-name='Path 7596' d='M16.561,33H8.744a.744.744,0,1,0,0,1.489h7.817a.744.744,0,1,0,0-1.489Z' transform='translate(-5.022 -17.577)' fill='#ff725e' />
                <path id='Path_7597' data-name='Path 7597' d='M16.561,41H8.744a.744.744,0,0,0,0,1.489h7.817a.744.744,0,0,0,0-1.489Z' transform='translate(-5.022 -22.6)' fill='#ff725e' />
                <path id='Path_7598' data-name='Path 7598' d='M43.328,25H37.744a.744.744,0,0,0-.744.744V31.7a.744.744,0,0,0,.744.744h5.584a.744.744,0,0,0,.744-.744V25.744A.744.744,0,0,0,43.328,25Zm-.744,5.956H38.489V26.489h4.095Z' transform='translate(-23.227 -12.555)' fill='#ff725e' />
            </g>
            <path id='Path_7599' data-name='Path 7599' d='M-61,2A21.024,21.024,0,0,0-82,23,21.024,21.024,0,0,0-61,44,21.024,21.024,0,0,0-40,23,21.024,21.024,0,0,0-61,2Zm0,40.6A19.622,19.622,0,0,1-80.6,23,19.622,19.622,0,0,1-61,3.4,19.622,19.622,0,0,1-41.4,23,19.622,19.622,0,0,1-61,42.6Z' transform='translate(586 1663)' fill='#ff725e' />
        </g>
    </svg>
}

ContactCard.defaultProps = defaultProps

export { ContactCard }