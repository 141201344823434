import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> { }

type PQSubscribeSubHeading = React.FC<Props>

const SubscribeSubHeading: PQSubscribeSubHeading = (props) => {
    const { width } = useDimension()

    return <a {...props} className={`pq-subscribe-sub-heading d-inline-block text-decoration-none text-uppercase text-white-secondary ff-dax-pro fw-regular ${width < 768 ? 'fs-16px' : 'fs-24px'} ${props.className ? props.className : ''}`} >{props.children}</a>
}

export default SubscribeSubHeading