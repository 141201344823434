import React from 'react'
import Footer from './Footer'
import SubscribeDescription from './SubscribeDescription'
import SubscribeSubHeading from './SubscribeSubHeading'

const ProjectFooter = () => {
    return <Footer>
        <div className='d-flex align-items-center justify-content-center'>
            <div className='row'>
                <div className='col-sm-6 my-2'>
                    <SubscribeSubHeading href='mailto:pragmiqoy@gmail.com' className='mb-16px'>START A PROJECT</SubscribeSubHeading>
                    <SubscribeDescription>We are ready for the challenge!<br />Contact us at: pragmiqoy@pragmiq.com</SubscribeDescription>
                </div>
                <div className='col-sm-6 my-2'>
                    <SubscribeSubHeading href='mailto:pragmiqoy@gmail.com' className='mb-16px'>JOBS</SubscribeSubHeading>
                    <SubscribeDescription>We are always looking for talent<br />pragmiqoy@pragmiq.com</SubscribeDescription>
                </div>
            </div>
        </div>
    </Footer>
}

export default ProjectFooter