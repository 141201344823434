import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Home from '../pages/Home'
import KnackBoxProject from '../pages/KnackBoxProject'
import MitatProject from '../pages/MitatProject'

const Router = () => {
    return <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/project/knackbox' element={<KnackBoxProject />} />
            <Route path='/project/mitat' element={<MitatProject />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    </BrowserRouter>
}

export default Router