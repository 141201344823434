import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQOwl = React.FC<Props>

const defaultProps: Props = {
    id: 'Group_2704',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '80.101',
    height: '79.365',
    viewBox: '0 0 80.101 79.365',
}

const Owl: PQOwl = (props: Props) => {
    return <svg {...props} data-name='Group 2704' className={`pq-owl ${props.className ? props.className : ''}`}>
        <path id='Path_7341' data-name='Path 7341' d='M34.466-.006c27.811-.43,35.151,16.061,35.151,35.874s7.606,36.775-35.151,35.874S-.685,55.68-.685,35.868,6.655.424,34.466-.006Z' transform='translate(5.608 7.59)' fill='#e2e2ce' />
        <g id='Group_1831' data-name='Group 1831' transform='translate(0 0)'>
            <g id='Group_1820' data-name='Group 1820' transform='translate(0 8.763)'>
                <g id='Group_1821' data-name='Group 1821' transform='translate(0 0)'>
                    <path id='Union_9' data-name='Union 9' d='M7.859,55.76.118,10.727a.707.707,0,0,1,.018-.809.651.651,0,0,1,.169-.2L8.4.209A.571.571,0,0,1,9.259.177a.7.7,0,0,1,.028.934l-7.981,9.37L9.049,55.5a.672.672,0,0,1-.482.78.565.565,0,0,1-.12.013A.615.615,0,0,1,7.859,55.76Z' transform='translate(0 0)' fill='#575757' />
                    <rect id='Rectangle_1578' data-name='Rectangle 1578' width='13.898' height='1.209' rx='0.604' transform='translate(1.13 55.495)' fill='#3b3b3b' />
                    <rect id='Rectangle_1580' data-name='Rectangle 1580' width='13.898' height='2.417' rx='1.209' transform='translate(1.13 55.495)' fill='#3b3b3b' opacity='0.24' />
                </g>
            </g>
            <g id='Group_184' data-name='Group 184' transform='translate(11.864 14.955)'>
                <path id='Path_6581' data-name='Path 6581' d='M33.693-1.236C54.65-4.992,67.386.475,67.386,2.585S52.3,6.407,33.693,6.407,0,4.7,0,2.585,12.735-1.192,33.693-1.236Z' transform='translate(0.85 51.054)' fill='rgba(31,31,31,0.16)' />
                <g id='Group_182' data-name='Group 182' transform='translate(0 0)'>
                    <g id='Group_182-2' data-name='Group 182' transform='translate(32.893 0) rotate(21)'>
                        <path id='Path_5734' data-name='Path 5734' d='M8.471,15.165S12.864,7.267,12.224,0A20.412,20.412,0,0,0,4.479,2.6c-.208.115-1.551.966-1.551.966s-.308-.085.155-.945C2.33,2.62.366,5.526,0,5.9,1.64,6.793,6.823,9.807,8.471,15.165Z' transform='translate(0 0)' fill='#d29769' />
                        <path id='Path_5735' data-name='Path 5735' d='M3.431,15.64A14.586,14.586,0,0,0,0,9.842c.611-4.4,2.911-8.03,5.994-9.7C6.326.084,6.661.037,7,0,7.641,7.267,3.431,15.64,3.431,15.64Z' transform='translate(5.223 0)' fill='#fcfcfc' />
                    </g>
                    <path id='Path_5729' data-name='Path 5729' d='M581.168,645.785a24.229,24.229,0,0,1,11.912-19.061c2.374-1.354,4.1-3.938,3.022-4.868,3.777,2.624-.648,14.57-5.288,18.7s-8.971,7.661-8.971,7.661Z' transform='translate(-550.06 -601.096)' fill='#d29769' />
                    <path id='Path_6066' data-name='Path 6066' d='M8.906,0C14.644,0,19.3,10.726,19.3,23.957,22.538,34.981,16.079,38.6,16.079,38.6a55.276,55.276,0,0,1-6.47.7,69.254,69.254,0,0,1-6.959-.7s-8.339-8.541-4.136-14.639C-1.485,10.726,3.167,0,8.906,0Z' transform='translate(13.626 14.52)' fill='#ff8e47' />
                    <path id='Path_6065' data-name='Path 6065' d='M11.609,0c5.473,0,6.745,7.3,6.745,23.485a62.647,62.647,0,0,1-1.107,8.848H4.409a50.408,50.408,0,0,1-1.355-8.848C3.055,7.3,6.137,0,11.609,0Z' transform='translate(11.22 16.494)' fill='#e9e1cc' />
                    <g id='Group_1826' data-name='Group 1826' transform='translate(17.079 14.601) rotate(159)'>
                        <path id='Path_5734-2' data-name='Path 5734' d='M8.471,0s4.393,7.9,3.753,15.165a20.412,20.412,0,0,1-7.745-2.6c-.208-.115-1.551-.966-1.551-.966s-.308.085.155.945C2.33,12.545.366,9.639,0,9.262,1.64,8.371,6.823,5.357,8.471,0Z' transform='translate(0 0.475)' fill='#d29769' />
                        <path id='Path_5735-2' data-name='Path 5735' d='M3.431,0A14.585,14.585,0,0,1,0,5.8c.611,4.4,2.911,8.03,5.994,9.7.332.054.667.1,1.007.138C7.641,8.373,3.431,0,3.431,0Z' transform='translate(5.223 0)' fill='#fcfcfc' />
                    </g>
                    <path id='Path_5731' data-name='Path 5731' d='M293.043,403.017c3.257,0,7.73.687,8.088,3.511a12.449,12.449,0,0,0,.32-4.161l1,1.956c.344-.639.638-5.077.638-6.273,0-8.665-7.336-15.689-16.386-15.689s-16.386,7.024-16.386,15.689a26.458,26.458,0,0,0,.931,6.118l.8-2.46s-.051,4.089.573,4.821c.4-2.787,4.463-3.511,7.71-3.511,3.523,0,3.111,5.663,6.378,5.663S289.542,403.017,293.043,403.017Z' transform='translate(-264.156 -379.937)' fill='#ff8e47' />
                    <path id='Path_5736' data-name='Path 5736' d='M5.392,3.985C4.894,5.78,3.317,6.936,1.87,6.568S-.346,4.446.152,2.651,2.227-.3,3.674.068,5.89,2.19,5.392,3.985Z' transform='translate(11.179 14.05) rotate(-22)' fill='#f4f2f2' />
                    <path id='Path_5737' data-name='Path 5737' d='M374.728,531.451a1.39,1.39,0,1,1-1.389-1.33A1.361,1.361,0,0,1,374.728,531.451Z' transform='translate(-357.675 -513.626)' fill='#231f20' />
                    <path id='Path_5738' data-name='Path 5738' d='M.152,3.985C.65,5.78,2.227,6.936,3.674,6.568S5.89,4.446,5.392,2.651,3.317-.3,1.869.068-.347,2.19.152,3.985Z' transform='translate(28.339 12.105) rotate(17)' fill='#f4f2f2' />
                    <path id='Path_5739' data-name='Path 5739' d='M542.59,531.451a1.39,1.39,0,1,0,1.389-1.33A1.361,1.361,0,0,0,542.59,531.451Z' transform='translate(-513.484 -513.528)' fill='#231f20' />
                    <path id='Path_5745' data-name='Path 5745' d='M349.9,478.528l3.431-.745A3.039,3.039,0,0,0,349.9,478.528Z' transform='translate(-337.392 -467.829)' fill='#231f20' stroke='#1f1f1f' strokeWidth='1' />
                    <path id='Path_5746' data-name='Path 5746' d='M559.717,477.783l3.431.745A3.039,3.039,0,0,0,559.717,477.783Z' transform='translate(-530.382 -467.829)' fill='#231f20' stroke='#1f1f1f' strokeWidth='1' />
                    <path id='Path_5747' data-name='Path 5747' d='M455.929,685.423l-2.578.385A1.837,1.837,0,0,0,455.929,685.423Z' transform='translate(-431.942 -659.189)' fill='#231f20' stroke='#1f1f1f' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' />
                    <path id='Path_6063' data-name='Path 6063' d='M680.542,626.5a6.333,6.333,0,0,0,1.609-3.089l.64,1.315,1.117-2.818c3.777,2.624-1.117,12.413-1.117,12.413s-2.55-2.441-3.476-3.571C677.309,628.3,680.542,626.5,680.542,626.5Z' transform='translate(-637.683 -601.115)' fill='#fcfcfc' />
                    <g id='Group_185' data-name='Group 185' transform='translate(4.035 26.146)'>
                        <g id='Group_176' data-name='Group 176' transform='translate(0 1.412)'>
                            <g id='Group_177' data-name='Group 177' transform='translate(1.856 0)'>
                                <g id='Group_218' data-name='Group 218' transform='translate(0 0.425)'>
                                    <g id='Path_6582' data-name='Path 6582' transform='translate(0.24 3.038) rotate(-79)' fill='#fff'>
                                        <path d='M 0.6314041018486023 16.4604549407959 L 0.5044870376586914 0.5604783892631531 C 1.59918749332428 0.8152886629104614 2.429082155227661 1.84357225894928 2.438821315765381 3.062689065933228 L 2.526141405105591 14.00278949737549 C 2.531681299209595 14.69748878479004 2.274271249771118 15.34262943267822 1.80130136013031 15.81938934326172 C 1.474550366401672 16.14875411987305 1.070034742355347 16.36855316162109 0.6314041018486023 16.4604549407959 Z' stroke='none' />
                                        <path d='M 1.010540723800659 1.316149711608887 L 1.125568032264709 15.72659206390381 C 1.240139245986938 15.65339660644531 1.347694993019104 15.56668663024902 1.446341276168823 15.46724891662598 C 1.824711322784424 15.08584880828857 2.030621290206909 14.56716918945312 2.026151180267334 14.00677871704102 L 1.938831329345703 3.066679000854492 C 1.93296217918396 2.33173942565918 1.561914205551147 1.682823181152344 1.010540723800659 1.316149711608887 M 1.192092895507812e-06 -1.9073486328125e-06 C 1.609681367874146 0.01337814331054688 2.925421237945557 1.382808685302734 2.938801288604736 3.058698654174805 L 3.026121377944946 13.99879932403564 C 3.039501428604126 15.67469882965088 1.745417356491089 17.02243804931641 0.1357712745666504 17.00905990600586 L 1.192092895507812e-06 -1.9073486328125e-06 Z' stroke='none' fill='#f3f3f3' />
                                    </g>
                                    <g id='Path_6583' data-name='Path 6583' transform='translate(0.24 2.969) rotate(-79)' fill='#fff'>
                                        <path d='M 0.3824273645877838 16.58969116210938 L 0.2521222829818726 0.2646421194076538 C 1.605965495109558 0.4099929332733154 2.677248954772949 1.612626314163208 2.688813924789429 3.060704946517944 L 2.774863958358765 13.84109497070312 C 2.780944108963013 14.60294532775879 2.497764110565186 15.31132507324219 1.977504014968872 15.83575534820557 C 1.542648673057556 16.27409934997559 0.9829574227333069 16.53715705871582 0.3824273645877838 16.58969116210938 Z' stroke='none' />
                                        <path d='M 0.5044896602630615 0.5604743957519531 L 0.630126953125 16.30074119567871 C 1.068757653236389 16.20884323120117 1.473273038864136 15.98904705047607 1.800024032592773 15.65967464447021 C 2.272984027862549 15.1829252243042 2.53041410446167 14.53778457641602 2.524873971939087 13.84308528900146 L 2.438823938369751 3.062694549560547 C 2.429084777832031 1.843578338623047 1.59918999671936 0.8152866363525391 0.5044896602630615 0.5604743957519531 M 4.053115844726562e-06 -5.7220458984375e-06 C 1.609673976898193 0.01337432861328125 2.925424098968506 1.382804870605469 2.938803911209106 3.05870532989502 L 3.024853944778442 13.83909511566162 C 3.0382239818573 15.51498508453369 1.744093418121338 16.86272430419922 0.1344940662384033 16.84934425354004 L 4.053115844726562e-06 -5.7220458984375e-06 Z' stroke='none' fill='#f3f3f3' />
                                    </g>
                                    <g id='Path_6584' data-name='Path 6584' transform='translate(0 4.11) rotate(-79)' fill='#fff'>
                                        <path d='M 0.3843432068824768 16.82925415039062 L 0.2521183788776398 0.2646352648735046 C 1.605961561203003 0.4099944233894348 2.677244901657104 1.612627744674683 2.688809871673584 3.060706615447998 L 2.776769876480103 14.08065605163574 C 2.782850027084351 14.84249687194824 2.499680042266846 15.5508861541748 1.979419946670532 16.0753059387207 C 1.544564604759216 16.5136604309082 0.9848732352256775 16.77671813964844 0.3843432068824768 16.82925415039062 Z' stroke='none' />
                                        <path d='M 0.5044858455657959 0.5604763031005859 L 0.6320426464080811 16.54031181335449 C 1.07067334651947 16.44841194152832 1.475188970565796 16.22861099243164 1.801939964294434 15.89924621582031 C 2.274899959564209 15.4224967956543 2.532320022583008 14.77734661102295 2.526779890060425 14.08264636993408 L 2.438819885253906 3.062686920166016 C 2.429080724716187 1.843569755554199 1.599186182022095 0.8152866363525391 0.5044858455657959 0.5604763031005859 M 0 -3.814697265625e-06 C 1.609679937362671 0.01337623596191406 2.925420045852661 1.382806777954102 2.938799858093262 3.058696746826172 L 3.02675986289978 14.07865619659424 C 3.04013991355896 15.75455665588379 1.746070265769958 17.10229682922363 0.1364099979400635 17.08891677856445 L 0 -3.814697265625e-06 Z' stroke='none' fill='#f3f3f3' />
                                    </g>
                                </g>
                                <g id='Group_219' data-name='Group 219' transform='translate(16.438)'>
                                    <g id='Path_6585' data-name='Path 6585' transform='translate(0.577 6.283) rotate(-101)' fill='#fff'>
                                        <path d='M 0.5044867992401123 16.44867706298828 L 0.631403923034668 0.5486899018287659 C 1.070104956626892 0.6405702829360962 1.474595427513123 0.8603500127792358 1.801351070404053 1.189727187156677 C 2.274281024932861 1.666447162628174 2.531681060791016 2.311607122421265 2.526141166687012 3.006367206573486 L 2.438821077346802 13.94645690917969 C 2.429081916809082 15.1655740737915 1.599187254905701 16.1938648223877 0.5044867992401123 16.44867706298828 Z' stroke='none' />
                                        <path d='M 1.125568270683289 1.282503128051758 L 1.01054048538208 15.69300365447998 C 1.561913967132568 15.32632732391357 1.932961940765381 14.67740631103516 1.938831090927124 13.94246673583984 L 2.026151180267334 3.002377510070801 C 2.03062105178833 2.441926956176758 1.824731111526489 1.923236846923828 1.446381092071533 1.54185676574707 C 1.347746133804321 1.442431449890137 1.240137338638306 1.355691909790039 1.125568270683289 1.282503128051758 M 0.1595273017883301 -1.9073486328125e-06 C 1.758247017860413 -1.9073486328125e-06 3.039435625076294 1.342639923095703 3.026121139526367 3.010356903076172 L 2.938801050186157 13.95044708251953 C 2.925420999526978 15.62634754180908 1.609681129455566 16.99577713012695 9.5367431640625e-07 17.00915718078613 L 0.1357710361480713 9.72747802734375e-05 C 0.1436307430267334 3.24249267578125e-05 0.1516828536987305 -1.9073486328125e-06 0.1595273017883301 -1.9073486328125e-06 Z' stroke='none' fill='#f3f3f3' />
                                    </g>
                                    <g id='Path_6586' data-name='Path 6586' transform='translate(0.734 6.184) rotate(-101)' fill='#fff'>
                                        <path d='M 0.2521220445632935 16.58480453491211 L 0.3824271559715271 0.2597632706165314 C 0.9830009937286377 0.3122967481613159 1.542716145515442 0.575344443321228 1.977553725242615 1.013662219047546 C 2.497783660888672 1.538052201271057 2.780943870544434 2.246452331542969 2.774863719940186 3.00837230682373 L 2.68881368637085 13.78874206542969 C 2.67724871635437 15.23682117462158 1.605965256690979 16.43945503234863 0.2521220445632935 16.58480453491211 Z' stroke='none' />
                                        <path d='M 0.6301267147064209 0.5486946105957031 L 0.5044891834259033 16.28896713256836 C 1.599183201789856 16.03416442871094 2.429084539413452 15.00587749481201 2.438823699951172 13.78675270080566 L 2.524873733520508 3.006372451782227 C 2.530413866043091 2.311612129211426 2.273003816604614 1.666441917419434 1.800073742866516 1.189722061157227 C 1.473318099975586 0.8603525161743164 1.068827867507935 0.6405754089355469 0.6301267147064209 0.5486946105957031 M 0.158250093460083 3.814697265625e-06 C 1.756979584693909 3.814697265625e-06 3.038158416748047 1.342645645141602 3.024853706359863 3.01036262512207 L 2.938803672790527 13.79074192047119 C 2.925423860549927 15.46664237976074 1.609673738479614 16.83607292175293 3.814697265625e-06 16.84944152832031 L 0.1344938278198242 0.000102996826171875 C 0.1423535346984863 3.62396240234375e-05 0.1504056453704834 3.814697265625e-06 0.158250093460083 3.814697265625e-06 Z' stroke='none' fill='#f3f3f3' />
                                    </g>
                                    <g id='Path_6587' data-name='Path 6587' transform='translate(0.739 7.37) rotate(-101)' fill='#fff'>
                                        <path d='M 0.2521183490753174 16.82436752319336 L 0.3843432068824768 0.2597574591636658 C 0.9849188923835754 0.3122910261154175 1.544623494148254 0.5753380060195923 1.979470014572144 1.013664722442627 C 2.49970006942749 1.53806471824646 2.782850027084351 2.246464729309082 2.776769876480103 3.008354663848877 L 2.688809871673584 14.02830505371094 C 2.677244901657104 15.47638320922852 1.605961561203003 16.67901611328125 0.2521183490753174 16.82436752319336 Z' stroke='none' />
                                        <path d='M 0.6320426464080811 0.5486907958984375 L 0.5044856071472168 16.52853012084961 C 1.599179625511169 16.27372550964355 2.429080724716187 15.24544048309326 2.438819885253906 14.0263147354126 L 2.526779890060425 3.006364822387695 C 2.532320022583008 2.311614990234375 2.274919986724854 1.666454315185547 1.801979899406433 1.18973445892334 C 1.475251197814941 0.8603763580322266 1.070704817771912 0.6405849456787109 0.6320426464080811 0.5486907958984375 M 0.1601486206054688 -3.814697265625e-06 C 1.758868336677551 -3.814697265625e-06 3.040074586868286 1.342647552490234 3.02675986289978 3.010354995727539 L 2.938799858093262 14.03030490875244 C 2.925420045852661 15.70620441436768 1.609679937362671 17.07563400268555 0 17.08900451660156 L 0.1364099979400635 9.5367431640625e-05 C 0.1442697048187256 2.86102294921875e-05 0.1523041725158691 -3.814697265625e-06 0.1601486206054688 -3.814697265625e-06 Z' stroke='none' fill='#f3f3f3' />
                                    </g>
                                </g>
                            </g>
                            <path id='Path_6058' data-name='Path 6058' d='M63.782-41.954c1.589-1.025,16.66,3.2,16.66,3.2v16.311S65.664-23.782,64.611-25.27,62.193-40.929,63.782-41.954Z' transform='translate(-63.057 44.607)' fill='#ffd15a' />
                            <rect id='Rectangle_59' data-name='Rectangle 59' width='3.039' height='16.311' transform='translate(17.386 5.849)' fill='#d29769' />
                            <path id='Path_6059' data-name='Path 6059' d='M81.353-41.98c-1.359-1.1-16.66,3.2-16.66,3.2v16.311s14.3-1.12,15.779-2.608S82.711-40.882,81.353-41.98Z' transform='translate(-44.268 44.633)' fill='#ffd15a' />
                        </g>
                        <g id='Group_180' data-name='Group 180' transform='translate(5.922)'>
                            <g id='Group_178' data-name='Group 178' transform='translate(0)'>
                                <path id='Path_6061' data-name='Path 6061' d='M3.279-2.565c2.974.813,3.279.891,3.279,1.991S5.09,1.417,3.279,1.417,0,.526,0-.574.306-3.378,3.279-2.565Z' transform='translate(0 2.777)' fill='#3b3b3b' />
                            </g>
                            <g id='Group_179' data-name='Group 179' transform='translate(26.66 10.877)'>
                                <g id='Path_6062' data-name='Path 6062' transform='translate(15.21 10.519)' fill='#e26737'>
                                    <path d='M-8.831-7.761l-1.938.594v1.914Z' stroke='none' />
                                    <path d='M -8.830622673034668 -7.760745048522949 L -10.76834201812744 -5.25206470489502 L -10.76834201812744 -7.166454792022705 L -8.830622673034668 -7.760745048522949 Z' stroke='none' fill='#96420e' />
                                </g>
                                <path id='Path_6061-2' data-name='Path 6061' d='M3.279-2.565C.306-1.751,0-1.673,0-.574S1.468,1.417,3.279,1.417,6.558.526,6.558-.574,6.253-3.378,3.279-2.565Z' transform='translate(0 2.777)' fill='#3b3b3b' />
                            </g>
                        </g>
                    </g>
                    <g id='Group_571' data-name='Group 571' transform='translate(14.708 45.73)'>
                        <path id='Path_6578' data-name='Path 6578' d='M-.846,0C.9,0,2.309,1.957,2.309,4.372S3,8.743,1.257,8.743-4,6.786-4,4.372-2.588,0-.846,0Z' transform='translate(4 0)' fill='#9e4c18' />
                        <path id='Path_6580' data-name='Path 6580' d='M-1.009,0C.643,0,1.982,1.957,1.982,4.372s.655,4.372-1,4.372S-4,6.786-4,4.372-2.661,0-1.009,0Z' transform='translate(4.334 0)' fill='#3b3b3b' />
                        <path id='Path_6579' data-name='Path 6579' d='M3.174,0A2.92,2.92,0,0,1,5.908,2.692c0,.929-2.568.673-4.323.673S0,2.611,0,1.682,1.419,0,3.174,0Z' transform='translate(1.188 5.52)' fill='#3b3b3b' />
                    </g>
                    <g id='Group_572' data-name='Group 572' transform='translate(24.94 45.73)'>
                        <path id='Path_6578-2' data-name='Path 6578' d='M-.71,0C-2.453,0-3.865,1.957-3.865,4.372s-.691,4.372,1.051,4.372S2.444,6.786,2.444,4.372,1.032,0-.71,0Z' transform='translate(4.652 0)' fill='#9e4c18' />
                        <path id='Path_6580-2' data-name='Path 6580' d='M-.881,0C-2.533,0-3.872,1.957-3.872,4.372s-.655,4.372,1,4.372S2.11,6.786,2.11,4.372.771,0-.881,0Z' transform='translate(4.652 0)' fill='#3b3b3b' />
                        <path id='Path_6579-2' data-name='Path 6579' d='M2.726,0A2.92,2.92,0,0,0-.008,2.692c0,.929,2.568.673,4.323.673S5.9,2.611,5.9,1.682,4.481,0,2.726,0Z' transform='translate(0.008 5.52)' fill='#3b3b3b' />
                    </g>
                    <g id='Group_1813' data-name='Group 1813' transform='translate(12.749 22.892)'>
                        <path id='Path_188' data-name='Path 188' d='M15.5,271.466c.417-.076,1.389-.1,2.137-.19.77-.107,1.734-.373,2.339-.449a6.418,6.418,0,0,1,1.173-.16c.223-.023.223.107.166.152a1.4,1.4,0,0,1-.381.1c-.309.069-.792.122-1.389.244-.576.1-1.374.274-2.008.381-.583.084-1.216.107-1.59.152a3.334,3.334,0,0,1-.662.015C15.215,271.656,15.121,271.534,15.5,271.466Z' transform='translate(-14.601 -270.498)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                        <path id='Path_189' data-name='Path 189' d='M1.556,1.027C2.01.966,2.492.875,3.082.783,3.657.669,4.42.456,5.01.349a14.872,14.872,0,0,1,1.518-.3A.867.867,0,0,1,7.046.03c.065.03.165.107-.115.2C6.6.3,5.794.41,5.2.547c-.6.122-1.194.289-1.82.419-.655.1-1.4.221-1.921.282s-.935.13-1.173.137c-.252-.015-.3-.1-.288-.129.007-.046.05-.069.324-.1S1.067,1.065,1.556,1.027Z' transform='translate(0 1.91) rotate(-7)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                        <path id='Path_190' data-name='Path 190' d='M5.969,304.838c.432-.244,1.36-.754,1.95-1.059.554-.312.935-.5,1.425-.747.475-.267,1.13-.625,1.439-.746s.381-.061.338.038a6.068,6.068,0,0,1-.662.4c-.468.228-1.281.609-1.935.967-.648.335-1.266.693-1.856,1.043-.619.327-1.3.754-1.691.952-.4.137-.511.167-.568.122-.065-.031.05-.183.23-.282a5.063,5.063,0,0,1,.748-.4c.2-.114.165-.084.583-.29Z' transform='translate(-3.7 -300.487)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                        <path id='Path_191' data-name='Path 191' d='M.258.284C.633.436,1.59.649,2.323.893c.712.289,1.561.625,2.216.914s1.31.647,1.626.747c.3.091.209-.046.187-.122a3.626,3.626,0,0,0-.36-.175c-.4-.16-1.187-.587-1.821-.868S2.834.855,2.273.611C1.69.406,1.086.261.74.147A3.609,3.609,0,0,0,.093,0C.007.033-.123.147.258.284Z' transform='translate(12.39 1.328) rotate(7)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                        <path id='Path_192' data-name='Path 192' d='M4,.271c-.633,0-1.349.037-1.925.015C1.487.3.911.271.567.256a.874.874,0,0,1-.5-.1C.012.122-.108.041.223,0c.309-.022,1.1.074,1.707.059S3.046.029,3.75.041,5.63.19,5.89.22c.225.074.246.134.253.179s-.1.045-.33.03C5.531.4,4.636.273,4,.271Z' transform='translate(12.641 0) rotate(8)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                        <path id='Path_193' data-name='Path 193' d='M4.753,1.237C4.292,1.085,3.227.8,2.637.628,2.011.46,1.479.3,1.1.209A3.778,3.778,0,0,0,.335,0C.126-.012-.046.026.011.118A4.387,4.387,0,0,0,.738.361c.309.076.748.16,1.288.312s1.367.4,2.022.617c.64.183,1.41.457,1.835.556a.972.972,0,0,0,.583,0c.043-.053-.115-.137-.28-.221A5.69,5.69,0,0,0,5.379,1.4C5.12,1.313,5.206,1.367,4.753,1.237Z' transform='translate(12.469 0.776) rotate(3)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                        <path id='Path_199' data-name='Path 199' d='M105.7,252.171a1.231,1.231,0,0,1-.827-.937.3.3,0,0,1,.1-.318.859.859,0,0,1,.528-.187,5.3,5.3,0,0,1,.754.1,1.151,1.151,0,0,0,.5-.051,3.063,3.063,0,0,1,1.051-.2c.17.017.282.051.311.17a1.111,1.111,0,0,1,0,.725.893.893,0,0,1-.643.7A3.021,3.021,0,0,1,105.7,252.171Z' transform='translate(-96.725 -250.57)' fill='#2d2d2d' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='0.036' fillRule='evenodd' />
                    </g>
                </g>
            </g>
            <g id='Group_1822' data-name='Group 1822' transform='matrix(0.966, 0.259, -0.259, 0.966, 5.29, 0)'>
                <g id='Group_1823' data-name='Group 1823' transform='translate(0 0)'>
                    <path id='Union_8' data-name='Union 8' d='M.037,4.413l-.03.02s.006-.015.019-.043L0,4.336.11,4.2A11.166,11.166,0,0,1,1.494,1.979,11.045,11.045,0,0,1,3.542.044L3.578,0,16.037,3.045l.045-.018s0,.009,0,.028l.026.006L16.07,3.1a18.272,18.272,0,0,1-3.519,7.745A18.3,18.3,0,0,1,5.7,15.9L5.62,16Z' transform='translate(0 0)' fill='#ffd15a' />
                    <path id='Path_7223' data-name='Path 7223' d='M2.176,0A18.234,18.234,0,0,1,4.351,8.3a18.234,18.234,0,0,1-2.176,8.3A18.234,18.234,0,0,1,0,8.3,18.234,18.234,0,0,1,2.176,0Z' transform='translate(14.391 1.659) rotate(39)' fill='#fff' />
                </g>
            </g>
            <g id='Group_1825' data-name='Group 1825' transform='translate(57.327 2.622)'>
                <g id='Ellipse_452' data-name='Ellipse 452' transform='translate(0)' fill='#fff' stroke='#d29769' strokeWidth='1'>
                    <ellipse cx='9.366' cy='9.366' rx='9.366' ry='9.366' stroke='none' />
                    <ellipse cx='9.366' cy='9.366' rx='8.866' ry='8.866' fill='none' />
                </g>
                <line id='Line_16' data-name='Line 16' y2='1.209' transform='translate(9.366 1.511)' fill='none' stroke='#707070' strokeLinecap='round' strokeWidth='1' />
                <line id='Line_17' data-name='Line 17' y2='1.209' transform='translate(9.366 16.013)' fill='none' stroke='#707070' strokeLinecap='round' strokeWidth='1' />
                <line id='Line_18' data-name='Line 18' x2='1.209' transform='translate(16.013 9.366)' fill='none' stroke='#707070' strokeLinecap='round' strokeWidth='1' />
                <line id='Line_19' data-name='Line 19' x2='1.209' transform='translate(1.511 9.366)' fill='none' stroke='#707070' strokeLinecap='round' strokeWidth='1' />
                <g id='Group_1824' data-name='Group 1824' transform='translate(6.345 5.136)'>
                    <line id='Line_20' data-name='Line 20' y1='2.417' x2='2.719' transform='translate(3.323 1.813)' fill='none' stroke='#ffdb80' strokeLinecap='round' strokeWidth='1' />
                    <line id='Line_21' data-name='Line 21' x1='3.323' y1='4.23' fill='none' stroke='#ffdb80' strokeLinecap='round' strokeWidth='1' />
                </g>
            </g>
            <g id='Group_1830' data-name='Group 1830' transform='translate(54.264 55.798)'>
                <rect id='Rectangle_1579' data-name='Rectangle 1579' width='24.775' height='4.834' rx='2.417' transform='translate(0.041 8.46)' fill='#d29769' />
                <g id='Group_1827' data-name='Group 1827' transform='translate(0.06 3.626)'>
                    <path id='Path_7226' data-name='Path 7226' d='M2.212,2H16.193a2.317,2.317,0,0,1,2.2,2.417V5.626a2.317,2.317,0,0,1-2.2,2.417L2.212,6.834A2.317,2.317,0,0,1,.01,4.417C.01,2.478,1,2,2.212,2Z' transform='translate(-0.01 -2)' fill='#575757' />
                    <g id='Path_7227' data-name='Path 7227' transform='translate(14.854 -2)' fill='#fafafa' strokeLinecap='round' strokeLinejoin='round'>
                        <path d='M 1.192730188369751 7.792311668395996 C 0.7478837966918945 7.770185947418213 0.2597693204879761 6.886600017547607 0.2597693204879761 5.625635147094727 L 0.2597693204879761 4.417095184326172 C 0.2597693204879761 3.139995098114014 0.7604193091392517 2.249995231628418 1.209749341011047 2.249995231628418 L 8.82858943939209 2.249995231628418 C 9.088409423828125 2.249995231628418 9.778569221496582 2.249995231628418 9.778569221496582 4.417095184326172 C 9.778569221496582 5.69419527053833 9.277919769287109 6.584185123443604 8.82858943939209 6.584185123443604 L 8.808878898620605 6.584185123443604 L 8.789419174194336 6.58727502822876 L 1.192730188369751 7.792311668395996 Z' stroke='none' />
                        <path d='M 1.209749221801758 2.499995231628418 C 0.9693994522094727 2.499995231628418 0.5097694396972656 3.246575355529785 0.5097694396972656 4.417095184326172 L 0.5097694396972656 5.625635147094727 C 0.5097694396972656 6.757790565490723 0.9397554397583008 7.493338108062744 1.185425758361816 7.540338516235352 L 8.750259399414062 6.340354919433594 C 8.776168823242188 6.336245059967041 8.802359580993652 6.334185123443604 8.82858943939209 6.334185123443604 C 9.068939208984375 6.334185123443604 9.528569221496582 5.587604999542236 9.528569221496582 4.417095184326172 C 9.528569221496582 3.415745258331299 9.376199722290039 2.951635360717773 9.248369216918945 2.738615036010742 C 9.143989562988281 2.5646653175354 9.030219078063965 2.499995231628418 8.82858943939209 2.499995231628418 L 1.209749221801758 2.499995231628418 M 1.209749221801758 1.999995231628418 L 8.82858943939209 1.999995231628418 C 9.49131965637207 1.999995231628418 10.02856922149658 2.477895259857178 10.02856922149658 4.417095184326172 C 10.02856922149658 5.752015113830566 9.49131965637207 6.834185123443604 8.82858943939209 6.834185123443604 L 1.209749221801758 8.04273509979248 C 0.5470190048217773 8.04273509979248 0.009769439697265625 6.960565090179443 0.009769439697265625 5.625635147094727 L 0.009769439697265625 4.417095184326172 C 0.009769439697265625 3.082165241241455 0.5470190048217773 1.999995231628418 1.209749221801758 1.999995231628418 Z' stroke='none' fill='#e9e1cc' />
                    </g>
                    <line id='Line_22' data-name='Line 22' x1='9.488' y2='0.604' transform='translate(15.134 1.209)' fill='none' stroke='#e9e1cc' strokeLinecap='round' strokeWidth='0.5' />
                    <line id='Line_23' data-name='Line 23' x1='9.427' y2='0.906' transform='translate(15.194 2.296)' fill='none' stroke='#e9e1cc' strokeLinecap='round' strokeWidth='0.5' />
                    <line id='Line_25' data-name='Line 25' x1='9.488' y2='1.209' transform='translate(15.134 3.324)' fill='none' stroke='#e9e1cc' strokeLinecap='round' strokeWidth='0.5' />
                </g>
                <g id='Group_1829' data-name='Group 1829' transform='translate(0)'>
                    <path id='Path_7226-2' data-name='Path 7226' d='M16.193,2H2.212A2.317,2.317,0,0,0,.01,4.417V5.626a2.317,2.317,0,0,0,2.2,2.417L16.193,6.834a2.317,2.317,0,0,0,2.2-2.417C18.395,2.478,17.409,2,16.193,2Z' transform='translate(6.488 -2)' fill='#ffd15a' />
                    <g id='Path_7227-2' data-name='Path 7227' transform='translate(-0.01 -2)' fill='#fafafa' strokeLinecap='round' strokeLinejoin='round'>
                        <path d='M 8.845611572265625 7.792311668395996 L 1.24892270565033 6.58727502822876 L 1.229462623596191 6.584185123443604 L 1.209752678871155 6.584185123443604 C 0.7604226469993591 6.584185123443604 0.2597626745700836 5.69419527053833 0.2597626745700836 4.417095184326172 C 0.2597626745700836 2.249995231628418 0.9499326944351196 2.249995231628418 1.209752678871155 2.249995231628418 L 8.828592300415039 2.249995231628418 C 9.277922630310059 2.249995231628418 9.778582572937012 3.139995098114014 9.778582572937012 4.417095184326172 L 9.778582572937012 5.625635147094727 C 9.778582572937012 6.886600017547607 9.290458679199219 7.770185947418213 8.845611572265625 7.792311668395996 Z' stroke='none' />
                        <path d='M 1.209753036499023 2.499995231628418 C 1.008122444152832 2.499995231628418 0.8943424224853516 2.5646653175354 0.7899627685546875 2.738615036010742 C 0.6621322631835938 2.951635360717773 0.5097627639770508 3.415745258331299 0.5097627639770508 4.417095184326172 C 0.5097627639770508 5.587604999542236 0.9694023132324219 6.334185123443604 1.209753036499023 6.334185123443604 C 1.235982894897461 6.334185123443604 1.262172698974609 6.336245059967041 1.288083076477051 6.340354919433594 L 8.852916717529297 7.540338516235352 C 9.098587036132812 7.493338108062744 9.528582572937012 6.757790565490723 9.528582572937012 5.625635147094727 L 9.528582572937012 4.417095184326172 C 9.528582572937012 3.246575355529785 9.068943023681641 2.499995231628418 8.828592300415039 2.499995231628418 L 1.209753036499023 2.499995231628418 M 1.209753036499023 1.999995231628418 L 8.828592300415039 1.999995231628418 C 9.4913330078125 1.999995231628418 10.02858257293701 3.082165241241455 10.02858257293701 4.417095184326172 L 10.02858257293701 5.625635147094727 C 10.02858257293701 6.960565090179443 9.4913330078125 8.04273509979248 8.828592300415039 8.04273509979248 L 1.209753036499023 6.834185123443604 C 0.5470123291015625 6.834185123443604 0.009762763977050781 5.752015113830566 0.009762763977050781 4.417095184326172 C 0.009762763977050781 2.477895259857178 0.5470123291015625 1.999995231628418 1.209753036499023 1.999995231628418 Z' stroke='none' fill='#e9e1cc' />
                    </g>
                    <line id='Line_22-2' data-name='Line 22' x2='9.488' y2='0.604' transform='translate(0.261 1.209)' fill='none' stroke='#e9e1cc' strokeLinecap='round' strokeWidth='0.5' />
                    <line id='Line_23-2' data-name='Line 23' x2='9.427' y2='0.906' transform='translate(0.261 2.296)' fill='none' stroke='#e9e1cc' strokeLinecap='round' strokeWidth='0.5' />
                    <line id='Line_25-2' data-name='Line 25' x2='9.488' y2='1.209' transform='translate(0.261 3.324)' fill='none' stroke='#e9e1cc' strokeLinecap='round' strokeWidth='0.5' />
                </g>
            </g>
        </g>
    </svg>
}

Owl.defaultProps = defaultProps

export { Owl }
