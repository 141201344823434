import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQGraph = React.FC<Props>

const defaultProps: Props = {
    id: 'Many_Line_Graph',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '32.037',
    height: '32.037',
    viewBox: '0 0 32.037 32.037'
}

const Graph: PQGraph = (props: Props) => {
    return <svg {...props} data-name='Many Line Graph' className={`pq-graph ${props.className ? props.className : ''}`}>
        <path id='Path_10502' data-name='Path 10502' d='M4.67,32.969V2H3.6V3.068H2V4.136H3.6v6.407H2v1.068H3.6v6.407H2v1.068H3.6v6.407H2v1.068H3.6V33.5a.534.534,0,0,0,.534.534h29.9V32.969Z' transform='translate(-2 -2)' fill='#ff725e' />
        <path id='Path_10503' data-name='Path 10503' d='M13.808,35.8l1.083,1.442a.534.534,0,0,0,.923-.122l1.655-4.143,1.094,2.553a.534.534,0,0,0,.908.123l1.615-2.018,1.228,2.456a.546.546,0,0,0,.5.295.534.534,0,0,0,.473-.342L28.1,23.554l1.131,3.394a.534.534,0,0,0,.923.165l1.587-1.985,1.779,4a.534.534,0,0,0,1.011-.112l1.6-8.009L35.085,20.8l-1.272,6.364-1.454-3.27a.534.534,0,0,0-.908-.117l-1.52,1.9-1.292-3.874a.534.534,0,0,0-.494-.365.521.521,0,0,0-.51.342l-4.9,12.753-1.058-2.121a.534.534,0,0,0-.895-.1L19.2,34.3l-1.251-2.919a.575.575,0,0,0-.5-.325.534.534,0,0,0-.49.335l-1.783,4.458-1.032-1.376a.534.534,0,0,0-.908.082L11.1,38.822l.956.477Z' transform='translate(-6.241 -10.762)' fill='#ff725e' />
        <rect id='Rectangle_2852' data-name='Rectangle 2852' width='1' height='1' transform='translate(6 2.037)' fill='#ff725e' />
        <rect id='Rectangle_2853' data-name='Rectangle 2853' width='6' height='1' transform='translate(8 2.037)' fill='#ff725e' />
        <rect id='Rectangle_2854' data-name='Rectangle 2854' width='8' height='1' transform='translate(6 4.037)' fill='#ff725e' />
        <rect id='Rectangle_2855' data-name='Rectangle 2855' width='8' height='1' transform='translate(6 6.037)' fill='#ff725e' />
    </svg>

}

Graph.defaultProps = defaultProps

export { Graph }