import React from 'react'
import useDimension from '../hooks/useDimension'
import BulletText from './BulletText'
import OurServicesContainer from './OurServicesContainer'
import { ContactCard, Image, ScriptBoard } from './svg'

type CardType = 'Graphic' | 'UI' | 'UX'

const OurServices = () => {
    const { width } = useDimension()
    const [expandedCard, setExpandedCard] = React.useState<CardType | null>('Graphic')

    const cardClickHandler = (cardType: CardType) => {
        if (cardType === expandedCard) {
            setExpandedCard(null)
        } else {
            setExpandedCard(cardType)
        }
    }

    return <React.Fragment>
        <div className={`text-orange ff-noto-sans fw-semi-bold ${width < 768 ? 'text-center fs-14px my-20px' : 'fs-18px mt-80px mb-20px'}`}>Our services:</div>
        <div className='row align-items-stretch'>
            <div className='col-md-6 col-lg-4 my-2'>
                <OurServicesContainer
                    Icon={Image}
                    heading='Graphic design'
                    expanded={expandedCard === 'Graphic'}
                    onCardClick={() => cardClickHandler('Graphic')}
                >
                    <BulletText>Custom illustrations and animations</BulletText>
                    <BulletText>Logo design</BulletText>
                    <BulletText>Branding</BulletText>
                </OurServicesContainer>
            </div>
            <div className='col-md-6 col-lg-4 my-2'>
                <OurServicesContainer
                    Icon={ContactCard}
                    heading='UI design'
                    expanded={expandedCard === 'UI'}
                    onCardClick={() => cardClickHandler('UI')}
                >
                    <BulletText>CustomPattern library</BulletText>
                    <BulletText>Clickable prototype with HTML &amp; CSS</BulletText>
                    <BulletText>Responsive and adaptive designs</BulletText>
                </OurServicesContainer>
            </div>
            <div className='col-md-6 col-lg-4 my-2'>
                <OurServicesContainer
                    Icon={ScriptBoard}
                    heading='UX design'
                    expanded={expandedCard === 'UX'}
                    onCardClick={() => cardClickHandler('UX')}
                >
                    <BulletText>Craft solutions relevant to the current market, trends &amp; target users</BulletText>
                    <BulletText>Site mapping &amp; concept generation</BulletText>
                    <BulletText>User testing</BulletText>
                </OurServicesContainer>
            </div>
        </div>
        <div className={`mt-22px mb-28px fs-14px text-extra-dark-gray fw-400 ff-noto-sans fw-regular ${width < 768 ? 'text-center' : 'text-end'}`}>P.S To know more, you may contact us</div>
    </React.Fragment>
}

export default OurServices