import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>
}

type PQShortDescription = React.FC<Props>

const ShortDescription: PQShortDescription = ({ Icon, ...props }) => {
    const { width } = useDimension()

    return <div {...props} className={`w-100 ${props.className ? props.className : ''}`}>
        <div className='hstack'>
            <div className='w-32px'>
                <Icon />
            </div>
            <div className={`ms-16px ff-noto-sans fw-regular text-extra-dark-gray ${width < 768 ? 'fs-12px' : 'fs-14px'}`} style={{ width: 'calc(100% - 32px)' }}>
                {props.children}
            </div>
        </div>
    </div>
}

export default ShortDescription