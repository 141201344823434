import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQArrowDown = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '15.496',
    height: '14.253',
    viewBox: '0 0 15.496 14.253',
}

const ArrowDown: PQArrowDown = (props: Props) => {
    return <svg {...props} className={`pq-arrow-down ${props.className ? props.className : ''}`}>
        <g id='Icon_feather-arrow-right' data-name='Icon feather-arrow-right' transform='translate(13.374 1.5) rotate(90)'>
            <path id='Path_7072' data-name='Path 7072' d='M0,0H11.253' transform='translate(0 5.627)' fill='none' stroke='#cacaca' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' />
            <path id='Path_7073' data-name='Path 7073' d='M0,0,5.627,5.627,0,11.253' transform='translate(5.627)' fill='none' stroke='#cacaca' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' />
        </g>
    </svg>
}

ArrowDown.defaultProps = defaultProps

export { ArrowDown }
