import React from 'react'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> { }

type PQBullet = React.FC<Props>

const Bullet: PQBullet = (props) => {
    return <span {...props} className={`d-inline-block w-4px h-4px rounded-circle bg-dark-blue ${props.className ? props.className : ''}`} />
}

export default Bullet