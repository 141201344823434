import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import useDimension from '../hooks/useDimension'
import { ArrowRight } from './svg/ArrowRight'

interface Props extends LinkProps { }

type PQProjectHeaderNextLink = React.FC<Props>

const ProjectHeaderNextLink: PQProjectHeaderNextLink = (props) => {
    const { width } = useDimension()

    return <Link {...props} className={`pq-project-header-next-link text-decoration-none d-block ${props.className ? props.className : ''}`}>
        <div className='d-flex align-items-center justify-content-center'>
            <div className={`children text-dark-blue ff-dax-pro fw-regular ${width < 768 ? 'fs-14px' : 'fs-24px'}`}>{props.children}</div>
            <ArrowRight width={25} height={25} />
        </div>
    </Link>
}

export default ProjectHeaderNextLink