import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQDog = React.FC<Props>

const defaultProps: Props = {
    id: 'Group_3109',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '92.314',
    height: '73.698',
    viewBox: '0 0 92.314 73.698',
}

const Dog: PQDog = (props: Props) => {
    return <svg {...props} data-name='Group 3109' className={`pq-dog ${props.className ? props.className : ''}`}>
        <path id='Path_7412' data-name='Path 7412' d='M44.948-.006C80.931-.448,90.427,16.482,90.427,36.821s9.841,37.752-45.479,36.827S-.531,57.16-.531,36.821,8.965.435,44.948-.006Z' transform='translate(1.209 0.015)' fill='#9cb3e8' />
        <g id='Group_1916' data-name='Group 1916' transform='translate(13.075 8.596)'>
            <path id='Path_7333' data-name='Path 7333' d='M32.659-.018c18.551,0,32.156,3.426,32.156,6.691s-17.452,5.455-36,5.455S-2.661,11.47-2.087,5.4,14.107-.018,32.659-.018Z' transform='translate(4.045 45.1)' fill='rgba(0,0,0,0.24)' />
            <g id='Group_1909' data-name='Group 1909' transform='translate(0 24.85)'>
                <path id='Path_7335' data-name='Path 7335' d='M-4.533-6.006C2.132-10.354-.378,2.925.491,2.87S-3.113-9.554,9.637-10.2s8.7,12.306,9.9,13.07S16.815-11.349,27.4-6.7,26.141,8.432,26.141,8.432H-4.535S-11.2-1.659-4.533-6.006Z' transform='translate(8.89 10.224)' fill='#879445' />
                <g id='Group_1908' data-name='Group 1908' transform='translate(0 1.61)'>
                    <g id='Group_1904' data-name='Group 1904' transform='translate(26.811 3.382)'>
                        <path id='Path_7120' data-name='Path 7120' d='M880.466,669.286a11.325,11.325,0,0,0-6.665,4.557,5.447,5.447,0,0,0-.813,1.652,3.829,3.829,0,0,0,.005,1.9,8.555,8.555,0,0,1,.35,1.823,1.941,1.941,0,0,1-.709,1.539,4.466,4.466,0,0,1-1.9.8,4.391,4.391,0,0,0-2.11.883c-.069.065-.176-.069-.106-.134.994-.936,2.533-.771,3.672-1.44a1.87,1.87,0,0,0,1-1.514,6.6,6.6,0,0,0-.323-1.853,4.152,4.152,0,0,1-.07-1.869,5.115,5.115,0,0,1,.747-1.692,11.184,11.184,0,0,1,2.77-2.9,11.506,11.506,0,0,1,4.123-1.911C880.524,669.1,880.559,669.263,880.466,669.286Z' transform='translate(-868.496 -669.119)' fill='#96b365' />
                        <path id='Path_7121' data-name='Path 7121' d='M919.379,680.513a2.7,2.7,0,0,1-.472-3.4c.068-.1.236.027.168.123a2.5,2.5,0,0,0,.445,3.158C919.615,680.494,919.473,680.607,919.379,680.513Z' transform='translate(-914.629 -675.848)' fill='#96b365' />
                        <path id='Path_7123' data-name='Path 7123' d='M883.8,678.264c.29.434,3.854,1.674,5.18,1.327s.775.143-.286.242-4.713-.98-5.036-1.456a.1.1,0,0,1,0-.136A.1.1,0,0,1,883.8,678.264Z' transform='translate(-876.052 -675.695)' fill='#96b365' />
                    </g>
                    <g id='Group_1905' data-name='Group 1905' transform='matrix(0.174, -0.985, 0.985, 0.174, 8.697, 14.398)'>
                        <path id='Path_7120-2' data-name='Path 7120' d='M11.97.168A11.324,11.324,0,0,0,5.307,4.725a5.442,5.442,0,0,0-.813,1.652,3.831,3.831,0,0,0,0,1.9,8.549,8.549,0,0,1,.35,1.823,1.94,1.94,0,0,1-.709,1.539,4.468,4.468,0,0,1-1.9.8,4.39,4.39,0,0,0-2.11.877c-.069.065-.176-.069-.106-.134.994-.936,2.533-.772,3.671-1.44a1.87,1.87,0,0,0,1-1.514,6.6,6.6,0,0,0-.323-1.853A4.15,4.15,0,0,1,4.3,6.506a5.116,5.116,0,0,1,.746-1.688A11.186,11.186,0,0,1,7.811,1.912,11.508,11.508,0,0,1,11.934,0C12.027-.018,12.062.146,11.97.168Z' transform='translate(0 0)' fill='#96b365' />
                        <path id='Path_7121-2' data-name='Path 7121' d='M.869,3.429A2.7,2.7,0,0,1,.4.035c.068-.1.236.027.168.123a2.5,2.5,0,0,0,.445,3.158C1.1,3.411.963,3.524.869,3.429Z' transform='translate(3.88 1.237)' fill='#96b365' />
                        <path id='Path_7123-2' data-name='Path 7123' d='M.168.04c.289.434,3.854,1.674,5.18,1.327s.775.143-.286.243S.35.631.026.154a.1.1,0,0,1,0-.136A.1.1,0,0,1,.168.04Z' transform='translate(7.581 2.529)' fill='#96b365' />
                    </g>
                    <g id='Group_1906' data-name='Group 1906' transform='translate(17.33 0)'>
                        <path id='Path_7120-3' data-name='Path 7120' d='M875.377,673.76a10.775,10.775,0,0,0-1.575,1.823,5.441,5.441,0,0,0-.813,1.652,3.83,3.83,0,0,0,.005,1.9,8.55,8.55,0,0,1,.35,1.823,1.94,1.94,0,0,1-.709,1.539,4.466,4.466,0,0,1-1.9.8,4.391,4.391,0,0,0-2.11.883c-.069.065-.176-.069-.106-.134.994-.936,2.532-.772,3.672-1.44a1.87,1.87,0,0,0,1-1.514,6.6,6.6,0,0,0-.323-1.853,4.152,4.152,0,0,1-.071-1.869,5.114,5.114,0,0,1,.747-1.692,11.7,11.7,0,0,1,1.69-2.011C875.482,673.417,876.909,672.373,875.377,673.76Z' transform='translate(-868.497 -673.055)' fill='#96b365' />
                    </g>
                    <g id='Group_1907' data-name='Group 1907' transform='translate(13.234 11.035) rotate(135)'>
                        <path id='Path_7120-4' data-name='Path 7120' d='M6.88.7A10.787,10.787,0,0,0,5.307,2.528,5.442,5.442,0,0,0,4.491,4.18a3.831,3.831,0,0,0,.006,1.9A8.549,8.549,0,0,1,4.847,7.9a1.94,1.94,0,0,1-.709,1.539,4.468,4.468,0,0,1-1.9.8,4.39,4.39,0,0,0-2.11.879c-.069.065-.176-.069-.106-.134.994-.936,2.533-.772,3.671-1.44a1.87,1.87,0,0,0,1-1.514,6.6,6.6,0,0,0-.323-1.853A4.15,4.15,0,0,1,4.3,4.309,5.116,5.116,0,0,1,5.042,2.62,11.722,11.722,0,0,1,6.732.609C6.985.362,8.412-.681,6.88.7Z' transform='translate(0 0)' fill='#96b365' />
                    </g>
                </g>
            </g>
            <g id='Group_1910' data-name='Group 1910' transform='translate(41.498 27.448) rotate(9)'>
                <path id='Path_7335-2' data-name='Path 7335' d='M2.371,3.376c5.335-3.482,3.326,7.15,4.021,7.1S3.507.537,13.713.019s6.962,9.853,7.927,10.462S19.458-.9,27.93,2.82s-1.008,12.113-1.008,12.113H2.371S-2.963,6.856,2.371,3.376Z' transform='translate(1.117 0)' fill='#879445' />
                <g id='Group_1908-2' data-name='Group 1908' transform='translate(0 1.289)'>
                    <g id='Group_1904-2' data-name='Group 1904' transform='translate(21.461 2.706)'>
                        <path id='Path_7120-5' data-name='Path 7120' d='M9.582.135A9.064,9.064,0,0,0,4.246,3.783,4.357,4.357,0,0,0,3.6,5.1a3.066,3.066,0,0,0,0,1.522,6.846,6.846,0,0,1,.28,1.459,1.553,1.553,0,0,1-.568,1.231,3.576,3.576,0,0,1-1.521.637,3.513,3.513,0,0,0-1.689.7c-.053.053-.141-.053-.085-.106C.814,9.8,2.045,9.935,2.957,9.4a1.5,1.5,0,0,0,.8-1.212A5.282,5.282,0,0,0,3.494,6.7a3.321,3.321,0,0,1-.055-1.5,4.1,4.1,0,0,1,.595-1.352A8.952,8.952,0,0,1,6.25,1.532,9.211,9.211,0,0,1,9.553,0C9.627-.015,9.655.117,9.582.135Z' transform='translate(0 0)' fill='#96b365' />
                        <path id='Path_7121-3' data-name='Path 7121' d='M.7,2.745A2.161,2.161,0,0,1,.318.028c.053-.076.189.022.134.1A2,2,0,0,0,.809,2.654C.884,2.73.771,2.821.7,2.745Z' transform='translate(3.107 0.991)' fill='#96b365' />
                        <path id='Path_7123-3' data-name='Path 7123' d='M.136.033A7.579,7.579,0,0,0,4.282,1.095c1.062-.279.62.114-.229.194A9.719,9.719,0,0,1,.022.125a.079.079,0,0,1,0-.109A.082.082,0,0,1,.136.033Z' transform='translate(6.067 2.023)' fill='#96b365' />
                    </g>
                    <g id='Group_1905-2' data-name='Group 1905' transform='matrix(0.174, -0.985, 0.985, 0.174, 6.961, 11.525)'>
                        <path id='Path_7120-6' data-name='Path 7120' d='M9.582.135A9.064,9.064,0,0,0,4.246,3.783,4.357,4.357,0,0,0,3.6,5.1a3.066,3.066,0,0,0,0,1.522,6.846,6.846,0,0,1,.28,1.459,1.553,1.553,0,0,1-.567,1.231,3.575,3.575,0,0,1-1.521.637A3.513,3.513,0,0,0,.1,10.659c-.053.053-.141-.053-.085-.106C.814,9.8,2.045,9.935,2.957,9.4a1.5,1.5,0,0,0,.8-1.212A5.281,5.281,0,0,0,3.494,6.7a3.322,3.322,0,0,1-.055-1.5,4.094,4.094,0,0,1,.595-1.352A8.953,8.953,0,0,1,6.25,1.532,9.211,9.211,0,0,1,9.553,0C9.627-.015,9.655.117,9.582.135Z' transform='translate(0 0)' fill='#96b365' />
                        <path id='Path_7121-4' data-name='Path 7121' d='M.7,2.745A2.161,2.161,0,0,1,.318.028c.053-.076.189.022.135.1A2,2,0,0,0,.809,2.654C.884,2.73.771,2.821.7,2.745Z' transform='translate(3.107 0.99)' fill='#96b365' />
                        <path id='Path_7123-4' data-name='Path 7123' d='M.136.033A7.579,7.579,0,0,0,4.282,1.095c1.062-.279.62.114-.229.194A9.719,9.719,0,0,1,.022.125a.079.079,0,0,1,0-.109A.082.082,0,0,1,.136.033Z' transform='translate(6.067 2.023)' fill='#96b365' />
                    </g>
                    <g id='Group_1906-2' data-name='Group 1906' transform='translate(13.872 0)'>
                        <path id='Path_7120-7' data-name='Path 7120' d='M5.507.564a8.634,8.634,0,0,0-1.262,1.46A4.357,4.357,0,0,0,3.6,3.346a3.066,3.066,0,0,0,0,1.522,6.846,6.846,0,0,1,.28,1.459,1.553,1.553,0,0,1-.568,1.231,3.575,3.575,0,0,1-1.521.637A3.514,3.514,0,0,0,.1,8.9c-.053.053-.141-.053-.085-.106.8-.749,2.027-.618,2.939-1.153a1.5,1.5,0,0,0,.8-1.212,5.282,5.282,0,0,0-.259-1.483,3.321,3.321,0,0,1-.055-1.5A4.1,4.1,0,0,1,4.034,2.1,9.384,9.384,0,0,1,5.388.488C5.591.29,6.734-.546,5.507.564Z' transform='translate(0 0)' fill='#96b365' />
                    </g>
                    <g id='Group_1907-2' data-name='Group 1907' transform='translate(10.594 8.833) rotate(135)'>
                        <path id='Path_7120-8' data-name='Path 7120' d='M5.507.564a8.635,8.635,0,0,0-1.262,1.46A4.357,4.357,0,0,0,3.6,3.346a3.066,3.066,0,0,0,0,1.522,6.846,6.846,0,0,1,.28,1.459,1.553,1.553,0,0,1-.567,1.231,3.575,3.575,0,0,1-1.521.637A3.513,3.513,0,0,0,.1,8.9c-.053.053-.141-.053-.085-.106.8-.749,2.027-.618,2.939-1.153a1.5,1.5,0,0,0,.8-1.212,5.281,5.281,0,0,0-.259-1.483,3.322,3.322,0,0,1-.055-1.5A4.094,4.094,0,0,1,4.034,2.1,9.383,9.383,0,0,1,5.388.488C5.591.29,6.734-.546,5.507.564Z' transform='translate(0 0)' fill='#96b365' />
                    </g>
                </g>
            </g>
            <g id='Group_1901' data-name='Group 1901' transform='translate(3.115 0)'>
                <g id='Group_1898' data-name='Group 1898' transform='translate(8.631 8.661)'>
                    <path id='Path_7330' data-name='Path 7330' d='M0,1.463s7.212,4.474,14.024,0,1.48,2.583-1.761,4.256S0,5.72,0,5.72Z' transform='translate(40.884 33.888) rotate(20.972)' fill='#d29769' />
                    <g id='Group_1897' data-name='Group 1897' transform='translate(0 0)'>
                        <path id='Path_7323' data-name='Path 7323' d='M58.049,43.8c12.159,8.4,0,20.058,0,20.058a60.5,60.5,0,0,1-9.948.561c-2.956-.249-3.193-1.862,0-2.149,6.957-.627-.8-1.542-1.706-4.6a11.707,11.707,0,0,1,0-6.029c-8.773-4.851-9.148-5.264-9.148-5.264V63.858s-5.891.955-6.926,0S34.1,59.766,34.1,59.766,33.07,52.2,32.04,45.038c-.421-2.927-7.27-14.689-3.6-15.64C33.639,28.057,50.863,38.838,58.049,43.8Z' transform='translate(-25.556 -20.884)' fill='#d29769' />
                        <path id='Path_7322' data-name='Path 7322' d='M15.989-12.618C21.143.929,30.627-7.978,42.555,6.085S41.24,28.767,41.24,28.767a60.443,60.443,0,0,1-9.948.561c-2.956-.249-3.193-1.862,0-2.149,6.957-.627-.8-1.542-1.706-4.6a7.236,7.236,0,0,1,0-4.914c-8.773-4.851-9.148-5.264-9.148-5.264V28.767s-5.891.955-6.926,0S17.3,24.677,17.3,24.677s-1.168-5.112-2.2-12.269S9.648,5.474,8.781.27c-.257-1.539-2.83-9.039-2.83-9.039S13.481-20.866,15.989-12.618Z' transform='translate(-5.951 15.497)' fill='#e9e1cc' />
                    </g>
                </g>
                <g id='Group_1900' data-name='Group 1900' transform='translate(0)'>
                    <g id='Group_1899' data-name='Group 1899'>
                        <path id='Path_7329' data-name='Path 7329' d='M2.372,0S5.785,2.077,5.706,2.9,5.793,14,3.131,15.174C.383,15.047,0,13.231,0,13.231Z' transform='matrix(0.875, 0.485, -0.485, 0.875, 15.998, 8.427)' fill='#ffd15a' />
                        <g id='Group_1896' data-name='Group 1896'>
                            <g id='Group_1895' data-name='Group 1895' transform='translate(3.393)'>
                                <g id='Group_1894' data-name='Group 1894' transform='translate(0)'>
                                    <path id='Path_7324' data-name='Path 7324' d='M-.108-3.465a22.905,22.905,0,0,1,7.6-1.641,19.256,19.256,0,0,1,7.187,1.641s.344,10.3-3.124,15.364-4.97,3.472-8.667-1.187S-.108-3.465-.108-3.465Z' transform='translate(0.16 5.106)' fill='#d29769' />
                                </g>
                                <path id='Path_7327' data-name='Path 7327' d='M15455.5,6110.473a7.773,7.773,0,0,1,2.955,0c2.156.487,4.848,1.982,5.643,4,1.275,3.23.047,2.138-1.139,4.685s.275,4.5-1.043,4.6-.822-3.185-2.5-7.688S15455.5,6110.473,15455.5,6110.473Z' transform='translate(-15445.649 -6110.326)' fill='#e9e1cc' />
                            </g>
                            <g id='Group_1903' data-name='Group 1903' transform='translate(6.905 12.244)'>
                                <path id='Path_7334' data-name='Path 7334' d='M15478.2,6146.539c1.48,2.694,1.783,2.381,2.568,2.278s1.479-1.708,1.479-1.708.83,1.812,1.961,1.708,2.025-2.278,2.025-2.278a18.748,18.748,0,0,1-1.59,5.076c-1.078,1.9-3.146,1.811-4.529,0A13.765,13.765,0,0,1,15478.2,6146.539Z' transform='translate(-15478.196 -6146.539)' fill='#575757' />
                                <path id='Path_7331' data-name='Path 7331' d='M15483.479,6152.486c1.133,0,3.4-.146,2.326,1.756a2.494,2.494,0,0,1-2.254,1.39,3.084,3.084,0,0,1-2.275-1.39C15479.867,6152.428,15482.363,6152.486,15483.479,6152.486Z' transform='translate(-15479.372 -6149.167)' fill='#ffb4d7' />
                            </g>
                            <path id='Path_7326' data-name='Path 7326' d='M15464.257,6110.469a7.417,7.417,0,0,0-2.83,0c-2.064.465-4.641,1.9-5.4,3.834-1.217,3.094-.039,2.048,1.09,4.486s-.266,4.314,1,4.4.785-3.051,2.395-7.364S15464.257,6110.469,15464.257,6110.469Z' transform='translate(-15455.508 -6110.316)' fill='#e9e1cc' />
                            <path id='Subtraction_9' data-name='Subtraction 9' d='M6.326,12.409h0c-1.026,0-2.07-.723-3.273-2.276A18.174,18.174,0,0,1,0,4.9a.037.037,0,0,1,.006-.013A18.787,18.787,0,0,1,1.937,2.45,10.774,10.774,0,0,1,3.906.766,4.214,4.214,0,0,1,6.143,0,4.1,4.1,0,0,1,8.354.766a10.19,10.19,0,0,1,1.911,1.686A17.629,17.629,0,0,1,12.116,4.9v.006a9.206,9.206,0,0,1-.441,1.816A10.688,10.688,0,0,1,9.5,10.438C8.261,11.8,7.28,12.408,6.326,12.409ZM2.073,4.338h0A17.2,17.2,0,0,0,3.053,7.3c.6,1.349,1.617,2.958,3.036,2.958h.03c1.427-.022,2.434-1.63,3.029-2.972A16.824,16.824,0,0,0,10.1,4.344a.018.018,0,0,1,0,.006c-.159.362-.993,2.171-2.019,2.266-.031,0-.063,0-.094,0-1.018,0-1.782-1.534-1.865-1.708v0c-.074.164-.743,1.606-1.48,1.7-.062.008-.12.018-.178.027H4.459a2.224,2.224,0,0,1-.349.039C3.609,6.681,3.162,6.316,2.073,4.338Z' transform='translate(4.817 7.907)' fill='#e9e1cc' />
                            <path id='Polygon_21' data-name='Polygon 21' d='M.208.642a1.366,1.366,0,0,1,2.317,0h0a1.366,1.366,0,0,1-1.159,2.09h0A1.366,1.366,0,0,1,.208.642Z' transform='translate(12.306 12.168) rotate(180)' fill='#3b3b3b' />
                        </g>
                    </g>
                    <circle id='Ellipse_473' data-name='Ellipse 473' cx='0.697' cy='0.697' r='0.697' transform='translate(7.879 4.772)' fill='#3b3b3b' />
                    <circle id='Ellipse_474' data-name='Ellipse 474' cx='0.697' cy='0.697' r='0.697' transform='translate(13.452 4.772)' fill='#3b3b3b' />
                </g>
            </g>
            <g id='Group_1902' data-name='Group 1902' transform='translate(3.903 45.083)'>
                <path id='Union_13' data-name='Union 13' d='M.012,6.135a.1.1,0,0,1,0-.018A.272.272,0,0,1,0,6.089L.012,1.672c0-.235-.014-.408,0-.531a.221.221,0,0,1,0-.026C.012.5,2.634,0,5.87,0,9.027,0,11.6.478,11.72,1.072a3.509,3.509,0,0,1,0,.6l.019,4.516a.482.482,0,0,1-.036.032c-.228.575-2.756,1.029-5.838,1.029C2.634,7.249.012,6.75.012,6.135Z' transform='translate(0.002 0.002)' fill='#ffd15a' />
                <ellipse id='Ellipse_477' data-name='Ellipse 477' cx='5.856' cy='1.116' rx='5.856' ry='1.116' transform='translate(0.014 0)' fill='#d29769' />
            </g>
            <g id='Group_1914' data-name='Group 1914' transform='translate(26.228 3.347)' opacity='0'>
                <path id='Path_7219' data-name='Path 7219' d='M-.35,0H16.743a3.3,3.3,0,0,1,2.869,2.866V6.687a3.3,3.3,0,0,1-2.869,2.866H-.35A3.3,3.3,0,0,1-3.22,6.687L-6.393,2.866H-3.22A3.3,3.3,0,0,1-.35,0Z' transform='translate(6.393)' fill='#fff' />
                <text id='Please' transform='translate(6.369 5.123)' fill='#383838' fontSize='3' fontFamily='Raleway' fontWeight='500'><tspan x='0' y='0'>Please</tspan></text>
            </g>
            <g id='Group_1912' data-name='Group 1912' transform='translate(27.901 3.347)' opacity='0'>
                <path id='Path_7219-2' data-name='Path 7219' d='M-.148,0H13.563c.893,0,2.975,1.283,2.975,2.866V6.687c0,1.583-2.083,2.866-2.975,2.866H-.148c-.892,0-2.975-1.283-2.975-2.866l-3.16-3.821h3.16C-3.123,1.283-1.041,0-.148,0Z' transform='translate(6.283)' fill='#fff' />
                <text id='Sorry' transform='translate(6.369 5.123)' fill='#383838' fontSize='3' fontFamily='Raleway' fontWeight='500'><tspan x='0' y='0'>Sorry</tspan></text>
            </g>
            <g id='Group_1915' data-name='Group 1915' transform='translate(27.868 2.244)'>
                <path id='Path_7219-3' data-name='Path 7219' d='M1.329,0H30c1.668,0,4.034,1.427,4.034,3.184V7.435c0,1.76-2.366,3.184-4.034,3.184H1.329c-1.668,0-4.034-1.427-4.034-3.184L-6.49,3.184h3.785C-2.705,1.427-.339,0,1.329,0Z' transform='translate(6.49)' fill='#f3f3f3' />
                <text id='Thankyou' transform='translate(7.319 7.423)' fill='#383838' fontSize='6' fontFamily='Raleway' fontWeight='500'><tspan x='0' y='0'>Thankyou</tspan></text>
            </g>
        </g>
    </svg>
}

Dog.defaultProps = defaultProps

export { Dog }
