import React from 'react'
import StrokeText from './StrokeText'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    strokeText: string
    heading: string
    description: string
}

type PQHelpDescription = React.FC<Props>

const HelpDescription: PQHelpDescription = ({ description, heading, strokeText, ...props }) => {
    return <div {...props} className={`hstack ${props.className ? props.className : ''}`}>
        <StrokeText>{strokeText}</StrokeText>
        <div className='ms-25px ff-noto-sans'>
            <span className='fs-14px text-dark-blue fw-medium'>{heading}:</span>
            <span className='fs-14px text-extra-dark-gray fw-regular'>{description}</span>
        </div>
    </div>
}

export default HelpDescription