import React from "react";
import Description from "../components/Description";
import GoToTop from "../components/GoToTop";
import HelpContainer from "../components/HelpContainer";
import HorizontalLine from "../components/HorizontalLine";
import Intro from "../components/Intro";
import OurServices from "../components/OurServices";
import Projects from "../components/Projects";
import ResetSubscribeNewsletterStates from "../components/ResetSubscribeNewsletterStates";
import SubscribeContainer from "../components/SubscribeContainer";
import useDimension from "../hooks/useDimension";

const Home = () => {
  const { width } = useDimension();

  return (
    <React.Fragment>
      <div className={width < 768 ? "bg-kb-medium-blue" : ""}>
        <div
          className={`container ${width < 768 ? "pt-44px px-32px" : "pt-40px"}`}
        >
          <Intro />
        </div>
      </div>
      <div className={`container ${width < 768 ? "px-32px" : ""}`}>
        <Description />
        <HorizontalLine className="mt-41px mb-131px" />
        <Projects />
        <HorizontalLine className="mt-16px mb-132px" />
        <HelpContainer />
        <OurServices />
        <HorizontalLine className="mb-132px" />
      </div>
      <SubscribeContainer />
      <GoToTop />
      <ResetSubscribeNewsletterStates />
    </React.Fragment>
  );
};

export default Home;
