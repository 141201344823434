import React from 'react'
import SectionHeading from './SectionHeading'
import { Help } from '../components/svg'
import HelpDescription from './HelpDescription'
import useDimension from '../hooks/useDimension'

interface Props { }

type PQHelpContainer = React.FC<Props>

const HelpContainer: PQHelpContainer = (props) => {
    const { width } = useDimension()

    return <React.Fragment>
        <SectionHeading>HOW WE CAN HELP YOU GET THE RESULTS YOU DESIRE:</SectionHeading>
        <div className='mt-20px mb-60px text-center fs-14px ff-noto-sans fw-regular text-extra-dark-gray'>We simplify and break down a problem and create solutions that are intuitive, enjoyable and require minimal effort to use.</div>
        <div className='row'>
            {width >= 768 && <div className='col-lg-7'>
                <div className='d-flex justify-content-center'>
                    <Help />
                </div>
            </div>}
            <div className='col-lg-5'>
                <div className={`mb-24px fs-14px ff-noto-sans fw-medium ${width < 768 ? 'text-center text-orange' : 'ps-56px text-dark-blue'}`}>Our designs are:</div>
                <HelpDescription
                    description='We design for multiple screen sizes across various platforms.'
                    heading='Responsive/adaptive'
                    strokeText='01.'
                    className='mb-24px'
                />
                <HelpDescription
                    description='Designing an interface happens in stages. At each stage, the developer is provided with a design library and html/css codes.'
                    heading='Iterative'
                    strokeText='02.'
                    className='mb-24px'
                />
                <HelpDescription
                    description='Products are designed to accommodate future additions to the solutions as much as possible.'
                    heading='Sustainable'
                    strokeText='03.'
                    className='mb-24px'
                />
            </div>
        </div>
    </React.Fragment>
}

export default HelpContainer