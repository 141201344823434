import React from 'react'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

type PQSubscribeDescription = React.FC<Props>

const SubscribeDescription: PQSubscribeDescription = (props) => {
    return <div {...props} className={`fs-14px ff-noto-sans fw-regular text-white-secondary ${props.className ? props.className : ''}`} />
}

export default SubscribeDescription