import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQElephant = React.FC<Props>

const defaultProps: Props = {
    id: 'Group_2978',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '91.891',
    height: '79.399',
    viewBox: '0 0 91.891 79.399',
}

const Elephant: PQElephant = (props: Props) => {
    return <svg {...props} data-name='Group 2978' className={`pq-elephant ${props.className ? props.className : ''}`}>
        <path id='Path_7407' data-name='Path 7407' d='M44.736-.006C80.554-.444,90.007,16.359,90.007,36.546S99.8,74.017,44.736,73.1-.535,56.734-.535,36.546,8.919.432,44.736-.006Z' transform='translate(1.209 6.265)' fill='#c4e9f9' />
        <g id='Group_1843' data-name='Group 1843' transform='translate(0 0)'>
            <g id='Group_1842' data-name='Group 1842' transform='translate(23.169 41.806)'>
                <path id='Path_7238' data-name='Path 7238' d='M3.221,9.69c1.782,0,3.23-2.173,3.233-4.849S5.015,0,3.233,0,0,2.173,0,4.849,1.439,9.692,3.221,9.69Z' transform='matrix(1, -0.017, 0.017, 1, 0, 0.113)' fill='#acd261' />
                <path id='Union_10' data-name='Union 10' d='M.46.246a8.033,8.033,0,0,1,.882,3.605A31.873,31.873,0,0,1,1.375,10.4H.936a31.813,31.813,0,0,0,0-6.541A8,8,0,0,0,.08.261C-.182-.128.267-.039.46.246Z' transform='translate(2.411 4.73)' fill='#d29769' />
            </g>
            <g id='Group_1838' data-name='Group 1838' transform='translate(0 11.012)'>
                <ellipse id='Ellipse_453' data-name='Ellipse 453' cx='9.825' cy='14.895' rx='9.825' ry='14.895' fill='#acd261' />
                <path id='Union_10-2' data-name='Union 10' d='M2.163,38.894s.392-9.116,0-17.507q-.015-.317-.035-.633l-.027,0s0-.087.011-.247A50.939,50.939,0,0,0,.673,11.762c-.261-1.042.187-.8.381-.039a53.633,53.633,0,0,1,1.23,6.419,43.087,43.087,0,0,1,.837-5.428c.089-.385.176-.743.263-1.08a25.113,25.113,0,0,0-.707-5.506A28.7,28.7,0,0,0,0,.144S.088-.511.856.971a19.489,19.489,0,0,1,2.219,5.1,23.179,23.179,0,0,1,.675,4.244A44.426,44.426,0,0,1,5.609,5.549c.849-2.088.908-1.184.908-1.184a55.27,55.27,0,0,0-2.7,7.253c0,.153,0,.237,0,.237l-.07.009c-.082.306-.157.609-.225.908A48.589,48.589,0,0,0,2.537,20.8q.018.287.032.575c.407,8.391.033,17.523.033,17.523Z' transform='translate(7.109 7.312)' fill='#d29769' />
            </g>
            <g id='Group_1850' data-name='Group 1850' transform='translate(0 41.616)'>
                <path id='Path_7238-2' data-name='Path 7238' d='M4.541,0c2.5-.009,4.519,3,4.51,6.713S7.01,13.449,4.51,13.458s-4.519-3-4.51-6.713S2.042.009,4.541,0Z' transform='translate(9.051 13.458) rotate(180)' fill='#879445' />
                <g id='Group_1884' data-name='Group 1884' transform='translate(1.553 4.412)'>
                    <path id='Union_10-3' data-name='Union 10' d='M.46,10.152a8.033,8.033,0,0,0,.882-3.605A31.874,31.874,0,0,0,1.375,0H.936a31.813,31.813,0,0,1,0,6.541,8,8,0,0,1-.857,3.6C-.182,10.527.267,10.437.46,10.152Z' transform='translate(4.126 14.289) rotate(180)' fill='#d29769' />
                    <path id='Union_12' data-name='Union 12' d='M.547.213C1.375,1.1,1.393,2.2,1.6,3.334A20.141,20.141,0,0,1,1.635,9H1.4A28.537,28.537,0,0,0,1.113,3.34C.919,2.208.915,1.117.095.226-.216-.111.317-.033.547.213Z' transform='translate(0 0.285) rotate(-9)' fill='#d29769' />
                </g>
            </g>
            <g id='Group_1839' data-name='Group 1839' transform='translate(64.653 2.245)'>
                <ellipse id='Ellipse_453-2' data-name='Ellipse 453' cx='12.994' cy='19.966' rx='12.994' ry='19.966' transform='translate(0 0)' fill='#acd261' />
                <path id='Union_10-4' data-name='Union 10' d='M2.863,0s.518,12.063,0,23.166q-.02.42-.046.838l-.036,0s0,.115.014.327A67.405,67.405,0,0,1,.891,35.9c-.346,1.379.248,1.061.5.051A70.97,70.97,0,0,0,3.022,27.46,57.015,57.015,0,0,0,4.13,34.643c.117.509.233.984.348,1.429a33.23,33.23,0,0,1-.935,7.286A37.978,37.978,0,0,1,0,51.276s.116.867,1.133-1.094A25.789,25.789,0,0,0,4.07,43.43a30.672,30.672,0,0,0,.893-5.615,58.786,58.786,0,0,0,2.459,6.311c1.123,2.762,1.2,1.567,1.2,1.567a73.136,73.136,0,0,1-3.576-9.6c0-.2,0-.313,0-.313l-.093-.011c-.108-.4-.208-.806-.3-1.2a64.3,64.3,0,0,1-1.3-10.62q.024-.379.043-.761C3.939,12.084,3.443,0,3.443,0Z' transform='translate(16.595 59.897) rotate(180)' fill='#d29769' />
            </g>
            <g id='Group_1840' data-name='Group 1840' transform='translate(33.84 0)'>
                <ellipse id='Ellipse_453-3' data-name='Ellipse 453' cx='13.596' cy='20.545' rx='13.596' ry='20.545' transform='translate(0)' fill='#879445' />
                <path id='Union_10-5' data-name='Union 10' d='M2.352,56.3s.542-12.618,0-24.232A68.968,68.968,0,0,0,.29,18.748c-.362-1.443.259-1.11.527-.054a68.872,68.872,0,0,1,2.1,13.355c.564,11.614.045,24.253.045,24.253ZM2.267,22.949s.093-2.767.545-6.205A31.252,31.252,0,0,1,.883,11.607,27,27,0,0,1,.013,3.955c-.1-2.309.455-1.584.455-1.584A39.726,39.726,0,0,0,1.4,11.4a29.507,29.507,0,0,0,1.564,4.275c.187-1.255.421-2.568.716-3.845A48.433,48.433,0,0,1,7.122,1.908C8.3-.981,8.378.269,8.378.269A70.915,70.915,0,0,0,4.234,11.9c-.359,1.583-.625,3.22-.822,4.734.633,1.315,1.135,2.176,1.135,2.176l-.553.235s-.293-.509-.713-1.342c-.344,3.014-.412,5.315-.412,5.315Z' transform='translate(10.236 7.652)' fill='#d29769' />
            </g>
            <g id='Group_1841' data-name='Group 1841' transform='translate(80.341 46.218)'>
                <ellipse id='Ellipse_453-4' data-name='Ellipse 453' cx='3.803' cy='5.705' rx='3.803' ry='5.705' transform='matrix(1, -0.017, 0.017, 1, 0, 0.133)' fill='#879445' />
                <path id='Union_10-6' data-name='Union 10' d='M.46.352a15.533,15.533,0,0,1,.882,5.143,64.784,64.784,0,0,1,.033,9.341H.936a64.655,64.655,0,0,0,0-9.332A15.5,15.5,0,0,0,.08.372C-.182-.183.267-.055.46.352Z' transform='translate(3.364 5.322)' fill='#d29769' />
            </g>
            <g id='Group_1885' data-name='Group 1885' transform='translate(0 11.012)' opacity='0'>
                <ellipse id='Ellipse_453-5' data-name='Ellipse 453' cx='9.825' cy='14.895' rx='9.825' ry='14.895' fill='rgba(0,0,0,0.24)' />
                <path id='Union_10-7' data-name='Union 10' d='M2.163,38.894s.392-9.116,0-17.507q-.015-.317-.035-.633l-.027,0s0-.087.011-.247A50.939,50.939,0,0,0,.673,11.762c-.261-1.042.187-.8.381-.039a53.633,53.633,0,0,1,1.23,6.419,43.087,43.087,0,0,1,.837-5.428c.089-.385.176-.743.263-1.08a25.113,25.113,0,0,0-.707-5.506A28.7,28.7,0,0,0,0,.144S.088-.511.856.971a19.489,19.489,0,0,1,2.219,5.1,23.179,23.179,0,0,1,.675,4.244A44.426,44.426,0,0,1,5.609,5.549c.849-2.088.908-1.184.908-1.184a55.27,55.27,0,0,0-2.7,7.253c0,.153,0,.237,0,.237l-.07.009c-.082.306-.157.609-.225.908A48.589,48.589,0,0,0,2.537,20.8q.018.287.032.575c.407,8.391.033,17.523.033,17.523Z' transform='translate(7.109 7.312)' fill='rgba(0,0,0,0.24)' />
            </g>
            <g id='Group_1886' data-name='Group 1886' transform='translate(0 41.616)' opacity='0'>
                <path id='Path_7238-3' data-name='Path 7238' d='M4.541,0c2.5-.009,4.519,3,4.51,6.713S7.01,13.449,4.51,13.458s-4.519-3-4.51-6.713S2.042.009,4.541,0Z' transform='translate(9.051 13.458) rotate(180)' fill='rgba(0,0,0,0.24)' />
                <g id='Group_1884-2' data-name='Group 1884' transform='translate(1.553 4.412)'>
                    <path id='Union_10-8' data-name='Union 10' d='M.46,10.152a8.033,8.033,0,0,0,.882-3.605A31.874,31.874,0,0,0,1.375,0H.936a31.813,31.813,0,0,1,0,6.541,8,8,0,0,1-.857,3.6C-.182,10.527.267,10.437.46,10.152Z' transform='translate(4.126 14.289) rotate(180)' fill='rgba(0,0,0,0.24)' />
                    <path id='Union_12-2' data-name='Union 12' d='M.547.213C1.375,1.1,1.393,2.2,1.6,3.334A20.141,20.141,0,0,1,1.635,9H1.4A28.537,28.537,0,0,0,1.113,3.34C.919,2.208.915,1.117.095.226-.216-.111.317-.033.547.213Z' transform='translate(0 0.285) rotate(-9)' fill='rgba(0,0,0,0.24)' />
                </g>
            </g>
            <g id='Group_1887' data-name='Group 1887' transform='translate(64.652 2.245)' opacity='0'>
                <ellipse id='Ellipse_453-6' data-name='Ellipse 453' cx='12.994' cy='19.966' rx='12.994' ry='19.966' transform='translate(0 0)' fill='rgba(0,0,0,0.24)' />
                <path id='Union_10-9' data-name='Union 10' d='M2.863,0s.518,12.063,0,23.166q-.02.42-.046.838l-.036,0s0,.115.014.327A67.405,67.405,0,0,1,.891,35.9c-.346,1.379.248,1.061.5.051A70.97,70.97,0,0,0,3.022,27.46,57.015,57.015,0,0,0,4.13,34.643c.117.509.233.984.348,1.429a33.23,33.23,0,0,1-.935,7.286A37.978,37.978,0,0,1,0,51.276s.116.867,1.133-1.094A25.789,25.789,0,0,0,4.07,43.43a30.672,30.672,0,0,0,.893-5.615,58.786,58.786,0,0,0,2.459,6.311c1.123,2.762,1.2,1.567,1.2,1.567a73.136,73.136,0,0,1-3.576-9.6c0-.2,0-.313,0-.313l-.093-.011c-.108-.4-.208-.806-.3-1.2a64.3,64.3,0,0,1-1.3-10.62q.024-.379.043-.761C3.939,12.084,3.443,0,3.443,0Z' transform='translate(16.595 59.897) rotate(180)' fill='rgba(0,0,0,0.24)' />
            </g>
            <g id='Group_1888' data-name='Group 1888' transform='translate(33.839 0)' opacity='0'>
                <ellipse id='Ellipse_453-7' data-name='Ellipse 453' cx='13.596' cy='20.545' rx='13.596' ry='20.545' transform='translate(0)' fill='rgba(0,0,0,0.24)' />
                <path id='Union_10-10' data-name='Union 10' d='M2.352,56.3s.542-12.618,0-24.232A68.968,68.968,0,0,0,.29,18.748c-.362-1.443.259-1.11.527-.054a68.872,68.872,0,0,1,2.1,13.355c.564,11.614.045,24.253.045,24.253ZM2.267,22.949s.093-2.767.545-6.205A31.252,31.252,0,0,1,.883,11.607,27,27,0,0,1,.013,3.955c-.1-2.309.455-1.584.455-1.584A39.726,39.726,0,0,0,1.4,11.4a29.507,29.507,0,0,0,1.564,4.275c.187-1.255.421-2.568.716-3.845A48.433,48.433,0,0,1,7.122,1.908C8.3-.981,8.378.269,8.378.269A70.915,70.915,0,0,0,4.234,11.9c-.359,1.583-.625,3.22-.822,4.734.633,1.315,1.135,2.176,1.135,2.176l-.553.235s-.293-.509-.713-1.342c-.344,3.014-.412,5.315-.412,5.315Z' transform='translate(10.236 7.652)' fill='rgba(0,0,0,0.24)' />
            </g>
            <g id='Group_1889' data-name='Group 1889' transform='translate(80.341 46.218)' opacity='0'>
                <ellipse id='Ellipse_453-8' data-name='Ellipse 453' cx='3.803' cy='5.705' rx='3.803' ry='5.705' transform='matrix(1, -0.017, 0.017, 1, 0, 0.133)' fill='rgba(0,0,0,0.24)' />
                <path id='Union_10-11' data-name='Union 10' d='M.46.352a15.533,15.533,0,0,1,.882,5.143,64.784,64.784,0,0,1,.033,9.341H.936a64.655,64.655,0,0,0,0-9.332A15.5,15.5,0,0,0,.08.372C-.182-.183.267-.055.46.352Z' transform='translate(3.364 5.322)' fill='rgba(0,0,0,0.24)' />
            </g>
            <g id='Group_1890' data-name='Group 1890' transform='translate(23.169 41.806)' opacity='0'>
                <path id='Path_7238-4' data-name='Path 7238' d='M3.221,9.69c1.782,0,3.23-2.173,3.233-4.849S5.015,0,3.233,0,0,2.173,0,4.849,1.439,9.692,3.221,9.69Z' transform='matrix(1, -0.017, 0.017, 1, 0, 0.113)' fill='rgba(0,0,0,0.24)' />
                <path id='Union_10-12' data-name='Union 10' d='M.46.246a8.033,8.033,0,0,1,.882,3.605A31.873,31.873,0,0,1,1.375,10.4H.936a31.813,31.813,0,0,0,0-6.541A8,8,0,0,0,.08.261C-.182-.128.267-.039.46.246Z' transform='translate(2.411 4.73)' fill='rgba(0,0,0,0.24)' />
            </g>
            <g id='Group_713' data-name='Group 713' transform='translate(7.456 4.869)'>
                <ellipse id='Ellipse_45' data-name='Ellipse 45' cx='38.374' cy='7.036' rx='38.374' ry='7.036' transform='translate(0 54.207)' fill='rgba(31,31,31,0.16)' />
                <g id='Group_508' data-name='Group 508' transform='translate(3.968 0)'>
                    <g id='Group_1883' data-name='Group 1883' transform='translate(13.071 49.587) rotate(45)'>
                        <ellipse id='Ellipse_454' data-name='Ellipse 454' cx='5.071' cy='4.754' rx='5.071' ry='4.754' fill='#879445' />
                        <g id='Group_1881' data-name='Group 1881' transform='translate(0.634 0.213)'>
                            <path id='Path_7232' data-name='Path 7232' d='M0,6.986A5.917,5.917,0,0,0,1.76,3.657,6.7,6.7,0,0,0,.343,0,10.63,10.63,0,0,1,.98,3.657,8.553,8.553,0,0,1,0,6.986Z' transform='translate(7.111 1.182)' fill='#acd261' />
                            <path id='Path_7233' data-name='Path 7233' d='M1.763,6.986A5.917,5.917,0,0,1,0,3.657,6.7,6.7,0,0,1,1.42,0,10.632,10.632,0,0,0,.783,3.657,8.554,8.554,0,0,0,1.763,6.986Z' transform='translate(0 0.944)' fill='#acd261' />
                            <path id='Path_7234' data-name='Path 7234' d='M0,8.874A7.958,7.958,0,0,0,1.873,4.645,9.428,9.428,0,0,0,.365,0a15.818,15.818,0,0,1,.678,4.645A12.393,12.393,0,0,1,0,8.874Z' transform='translate(5.357 0)' fill='#acd261' />
                            <path id='Path_7235' data-name='Path 7235' d='M1.876,8.874A7.958,7.958,0,0,1,0,4.645,9.429,9.429,0,0,1,1.511,0,15.821,15.821,0,0,0,.834,4.645,12.394,12.394,0,0,0,1.876,8.874Z' transform='translate(1.53 0)' fill='#acd261' />
                            <path id='Path_7236' data-name='Path 7236' d='M.615,8.874A25.005,25.005,0,0,0,1.1,4.645,33.647,33.647,0,0,0,.8,0,19.8,19.8,0,0,0,0,4.645,14.36,14.36,0,0,0,.615,8.874Z' transform='translate(3.831 0)' fill='#acd261' />
                        </g>
                    </g>
                    <g id='Group_507' data-name='Group 507' transform='translate(59.324 27.978)'>
                        <path id='Path_6139' data-name='Path 6139' d='M2.295,0a16.714,16.714,0,0,0-.861,7.025,44.276,44.276,0,0,0,2.21,9.27S4.167,20.246,2.1,18.6-.213,11.243.125,7.17A18.55,18.55,0,0,1,2.295,0Z' transform='matrix(-0.839, 0.545, -0.545, -0.839, 13.455, 15.937)' fill='#575757' />
                        <path id='Polygon_20' data-name='Polygon 20' d='M1.111,0,2.222,2.222H0Z' transform='translate(10.178 16.058)' fill='#383838' />
                    </g>
                    <path id='Path_6138' data-name='Path 6138' d='M6.758,4.12C7.5,2.72,9.136-.114,10.733,0,11.7.064,12.65,2.9,13.3,4.12l6.431,12.1c2.271,4.648-4.549,8.7-9.4,8.816-5.394.125-11.865-4.658-10-8.816Z' transform='matrix(0.719, -0.695, 0.695, 0.719, 33.991, 14.018)' fill='#575757' />
                    <g id='Group_1892' data-name='Group 1892' transform='translate(59.324 27.978)'>
                        <path id='Path_6139-2' data-name='Path 6139' d='M2.295,0a16.714,16.714,0,0,0-.861,7.025,44.276,44.276,0,0,0,2.21,9.27S4.167,20.246,2.1,18.6-.213,11.243.125,7.17A18.55,18.55,0,0,1,2.295,0Z' transform='matrix(-0.839, 0.545, -0.545, -0.839, 13.455, 15.937)' fill='#575757' />
                        <path id='Polygon_20-2' data-name='Polygon 20' d='M1.111,0,2.222,2.222H0Z' transform='translate(10.178 16.058)' fill='#383838' />
                    </g>
                    <path id='Path_7308' data-name='Path 7308' d='M7229.93,5353.162c11.871-7.5,18.548-6.233,23.963-4.819s16.518,6.634,21.39,13.091,9.612,16.022,10.254,23.122-1.154,20.1-1.154,20.1a9.935,9.935,0,0,1-3.619.8,11.7,11.7,0,0,1-3.815-.8v-6.269h-3.187v6.269a10.47,10.47,0,0,1-3.8.8,14.719,14.719,0,0,1-4.24-.8v-6.269h-8.089v6.269a11.566,11.566,0,0,1-3.929.8,13.52,13.52,0,0,1-4.13-.8v-6.269h-2.708v6.269a16.979,16.979,0,0,1-4.111.8,17.587,17.587,0,0,1-4.158-.8l-3.637-30.439c.352.058-.3-6.175-5.025-4.385s-8.059,12.336-1.394,21.083l-.937,2.708C7213.958,5382.8,7218.06,5360.658,7229.93,5353.162Z' transform='translate(-7219.048 -5342.788)' fill='#575757' />
                    <path id='Path_6140' data-name='Path 6140' d='M6.291,4.12C6.983,2.72,8.5-.114,9.991,0c.9.061,1.785,2.895,2.389,4.116l5.986,12.1c2.114,4.648-4.234,8.7-8.753,8.816-5.021.125-11.044-4.658-9.307-8.816Z' transform='translate(34.142 13.62) rotate(-41)' fill='#dc5656' />
                    <g id='Group_710' data-name='Group 710' transform='matrix(0.259, -0.966, 0.966, 0.259, 32.653, 5.923)'>
                        <g id='Group_249' data-name='Group 249' transform='matrix(0.391, 0.921, -0.921, 0.391, 2.365, 0.414)'>
                            <path id='Path_6136' data-name='Path 6136' d='M.358,1.145A4.94,4.94,0,0,0,.431.633,1.581,1.581,0,0,0,.468.109C.44.013.358-.03.294.023.284.045.248.045.22.312.183.536.028,1.242,0,1.445c.028.171.174.107.229.054A.65.65,0,0,0,.358,1.145Z' transform='translate(0 0.726)' fillRule='evenodd' />
                            <path id='Path_6137' data-name='Path 6137' d='M.35,1.95c.063-.2.1-.628.15-.849A5.252,5.252,0,0,0,.611.251C.619.029.524-.026.477.011.453-.008.445.121.366.472.295.9.042,1.858,0,2.283c-.024.332.142.2.19.185A1.617,1.617,0,0,0,.35,1.95Z' transform='translate(1.257 0) rotate(19)' fillRule='evenodd' />
                        </g>
                        <path id='Path_6746' data-name='Path 6746' d='M.35.557c.063.2.1.628.15.849a5.251,5.251,0,0,1,.111.85c.008.222-.087.277-.134.24-.024.018-.032-.111-.111-.462C.295,1.61.042.649,0,.225-.021-.107.145.022.192.04A1.617,1.617,0,0,1,.35.557Z' transform='translate(0.319 0.828) rotate(-95)' fillRule='evenodd' />
                    </g>
                    <g id='Group_711' data-name='Group 711' transform='translate(30.528 9.631) rotate(21)'>
                        <ellipse id='Ellipse_39' data-name='Ellipse 39' cx='1.481' cy='2.222' rx='1.481' ry='2.222' transform='translate(0 0)' fill='#fff' />
                        <ellipse id='Ellipse_42' data-name='Ellipse 42' cx='0.37' cy='0.741' rx='0.37' ry='0.741' transform='translate(0.454 2.665)' />
                    </g>
                    <path id='Path_6757' data-name='Path 6757' d='M0,.741a4.991,4.991,0,0,0,2.856.877A4.256,4.256,0,0,0,5.185,0' transform='translate(20.95 22.859)' fill='none' stroke='#e2e2e2' strokeLinecap='round' strokeWidth='1' opacity='0.24' />
                    <g id='Group_712' data-name='Group 712' transform='translate(25.537 6.054) rotate(21)'>
                        <ellipse id='Ellipse_39-2' data-name='Ellipse 39' cx='1.481' cy='2.222' rx='1.481' ry='2.222' transform='translate(0 0)' fill='#fff' />
                        <ellipse id='Ellipse_42-2' data-name='Ellipse 42' cx='0.37' cy='0.741' rx='0.37' ry='0.741' transform='translate(0.454 2.665)' />
                    </g>
                    <g id='Group_1882' data-name='Group 1882' transform='translate(3.682 44.077)'>
                        <ellipse id='Ellipse_454-2' data-name='Ellipse 454' cx='5.136' cy='4.834' rx='5.136' ry='4.834' transform='translate(0 0)' fill='#879445' />
                        <g id='Group_1881-2' data-name='Group 1881' transform='translate(0.739 0.518)'>
                            <path id='Path_7232-2' data-name='Path 7232' d='M-9.8,83.451a5.917,5.917,0,0,0-1.76,3.329,6.7,6.7,0,0,0,1.417,3.657,10.63,10.63,0,0,1-.637-3.657A8.553,8.553,0,0,1-9.8,83.451Z' transform='translate(11.565 -82.745)' fill='#acd261' />
                            <path id='Path_7233-2' data-name='Path 7233' d='M-11.565,83.451A5.917,5.917,0,0,1-9.806,86.78a6.7,6.7,0,0,1-1.416,3.657,10.632,10.632,0,0,0,.637-3.657A8.554,8.554,0,0,0-11.565,83.451Z' transform='translate(18.676 -82.507)' fill='#acd261' />
                            <path id='Path_7234-2' data-name='Path 7234' d='M-9.689,83.451a7.958,7.958,0,0,0-1.873,4.229,9.428,9.428,0,0,0,1.508,4.645,15.818,15.818,0,0,1-.678-4.645A12.393,12.393,0,0,1-9.689,83.451Z' transform='translate(13.206 -83.451)' fill='#acd261' />
                            <path id='Path_7235-2' data-name='Path 7235' d='M-11.565,83.451a7.958,7.958,0,0,1,1.872,4.229A9.429,9.429,0,0,1-11.2,92.325a15.821,15.821,0,0,0,.677-4.645A12.394,12.394,0,0,0-11.565,83.451Z' transform='translate(17.033 -83.451)' fill='#acd261' />
                            <path id='Path_7236-2' data-name='Path 7236' d='M-10.251,83.451a25.005,25.005,0,0,0-.481,4.229,33.647,33.647,0,0,0,.3,4.645,19.8,19.8,0,0,0,.8-4.645A14.36,14.36,0,0,0-10.251,83.451Z' transform='translate(14.679 -83.451)' fill='#acd261' />
                        </g>
                    </g>
                    <g id='Group_1880' data-name='Group 1880' transform='translate(1.869 54.316)'>
                        <g id='Group_1853' data-name='Group 1853' transform='translate(0 0.034)'>
                            <ellipse id='Ellipse_454-3' data-name='Ellipse 454' cx='4.834' cy='4.532' rx='4.834' ry='4.532' fill='#879445' />
                            <path id='Union_11' data-name='Union 11' d='M6.055,3.455A9.2,9.2,0,0,0,5.087,0,6.22,6.22,0,0,1,6.827,3.455a7.144,7.144,0,0,1-1.4,3.8A11.47,11.47,0,0,0,6.055,3.455Zm-2.385,0A18.065,18.065,0,0,1,4.118,0a10.41,10.41,0,0,1,.57,3.455,14.525,14.525,0,0,1-.739,3.8A24.361,24.361,0,0,1,3.669,3.455Zm-2.139,0A6.227,6.227,0,0,1,3.272,0,9.176,9.176,0,0,0,2.3,3.455a11.567,11.567,0,0,0,.629,3.8A7.139,7.139,0,0,1,1.53,3.455Zm5.992.037a6.389,6.389,0,0,0-.91-2.72,4.728,4.728,0,0,1,1.635,2.72A5.153,5.153,0,0,1,6.931,6.478,7.773,7.773,0,0,0,7.522,3.492ZM0,3.3A4.728,4.728,0,0,1,1.64.578,6.409,6.409,0,0,0,.73,3.3a7.785,7.785,0,0,0,.59,2.986A5.153,5.153,0,0,1,0,3.3Z' transform='translate(0.588 1.224)' fill='#acd261' />
                        </g>
                        <g id='Path_7237' data-name='Path 7237' transform='translate(0.017)' fill='#dc5656'>
                            <path d='M 4.753880977630615 6.72234058380127 C 2.270431041717529 6.72234058380127 0.2500009834766388 5.27060079574585 0.2500009834766388 3.486170768737793 C 0.2500009834766388 1.701740741729736 2.270431041717529 0.2500007748603821 4.753880977630615 0.2500007748603821 C 7.237330913543701 0.2500007748603821 9.257761001586914 1.701740741729736 9.257761001586914 3.486170768737793 C 9.257761001586914 5.27060079574585 7.237330913543701 6.72234058380127 4.753880977630615 6.72234058380127 Z' stroke='none' />
                            <path d='M 4.753880977630615 0.5000009536743164 C 3.589621067047119 0.5000009536743164 2.500831127166748 0.8282508850097656 1.688060760498047 1.424280643463135 C 0.921931266784668 1.986110687255859 0.5000009536743164 2.718370914459229 0.5000009536743164 3.486170768737793 C 0.5000009536743164 4.253971099853516 0.921931266784668 4.986230850219727 1.688060760498047 5.548060894012451 C 2.500831127166748 6.14409065246582 3.589621067047119 6.47234058380127 4.753880977630615 6.47234058380127 C 5.918140888214111 6.47234058380127 7.006931304931641 6.14409065246582 7.819701194763184 5.548060894012451 C 8.585830688476562 4.986230850219727 9.007761001586914 4.253971099853516 9.007761001586914 3.486170768737793 C 9.007761001586914 2.718370914459229 8.585830688476562 1.986110687255859 7.819701194763184 1.424280643463135 C 7.006931304931641 0.8282508850097656 5.918140888214111 0.5000009536743164 4.753880977630615 0.5000009536743164 M 4.753880977630615 9.5367431640625e-07 C 7.37938117980957 9.5367431640625e-07 9.507761001586914 1.560810565948486 9.507761001586914 3.486170768737793 C 9.507761001586914 5.411530494689941 7.37938117980957 6.97234058380127 4.753880977630615 6.97234058380127 C 2.12838077545166 6.97234058380127 9.5367431640625e-07 5.411530494689941 9.5367431640625e-07 3.486170768737793 C 9.5367431640625e-07 1.560810565948486 2.12838077545166 9.5367431640625e-07 4.753880977630615 9.5367431640625e-07 Z' stroke='none' fill='#879445' />
                        </g>
                        <g id='Ellipse_457' data-name='Ellipse 457' transform='translate(3.021 1.242)' fill='#fff' stroke='#575757' strokeWidth='0.5'>
                            <ellipse cx='0.302' cy='0.302' rx='0.302' ry='0.302' stroke='none' />
                            <ellipse cx='0.302' cy='0.302' rx='0.052' ry='0.052' fill='none' />
                        </g>
                        <g id='Ellipse_458' data-name='Ellipse 458' transform='translate(1.813 2.451)' fill='#fff' stroke='#575757' strokeWidth='0.5'>
                            <ellipse cx='0.302' cy='0.302' rx='0.302' ry='0.302' stroke='none' />
                            <ellipse cx='0.302' cy='0.302' rx='0.052' ry='0.052' fill='none' />
                        </g>
                        <g id='Ellipse_459' data-name='Ellipse 459' transform='translate(5.438 4.264)' fill='#fff' stroke='#575757' strokeWidth='0.5'>
                            <ellipse cx='0.604' cy='0.302' rx='0.604' ry='0.302' stroke='none' />
                            <ellipse cx='0.604' cy='0.302' rx='0.354' ry='0.052' fill='none' />
                        </g>
                        <g id='Ellipse_460' data-name='Ellipse 460' transform='translate(6.647 1.846)' fill='#fff' stroke='#575757' strokeWidth='0.5'>
                            <ellipse cx='0.302' cy='0.302' rx='0.302' ry='0.302' stroke='none' />
                            <ellipse cx='0.302' cy='0.302' rx='0.052' ry='0.052' fill='none' />
                        </g>
                        <g id='Ellipse_466' data-name='Ellipse 466' transform='translate(3.021 4.264)' fill='#fff' stroke='#575757' strokeWidth='0.5'>
                            <ellipse cx='0.302' cy='0.302' rx='0.302' ry='0.302' stroke='none' />
                            <ellipse cx='0.302' cy='0.302' rx='0.052' ry='0.052' fill='none' />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

Elephant.defaultProps = defaultProps

export { Elephant }
