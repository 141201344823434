import React from 'react'
import KnackboxAppScreensSvg from '../assets/svg/knackbox-app-screens.svg'
import Button from '../components/Button'
import { AppContext, AppContextType } from '../components/context/AppProvider'
import GoToTop from '../components/GoToTop'
import Input from '../components/Input'
import InputGroup from '../components/InputGroup'
import ProjectDescriptionBox from '../components/ProjectDescriptionBox'
import ProjectFooter from '../components/ProjectFooter'
import ProjectHeader from '../components/ProjectHeader'
import ResetSubscribeNewsletterStates from '../components/ResetSubscribeNewsletterStates'
import StrokeText from '../components/StrokeText'
import { ArrowDown, Box, Crocodile, Dog, Elephant, Knackbox, Owl, Plus, Tasks } from '../components/svg'
import Screen1 from '../components/svg/Screen1'
import Screen2 from '../components/svg/Screen2'
import Screen3 from '../components/svg/Screen3'
import Screen4 from '../components/svg/Screen4'
import Screen5 from '../components/svg/Screen5'
import useDimension from '../hooks/useDimension'

interface Props { }

type PQKnackBoxProject = React.FC<Props>

const KnackBoxProject: PQKnackBoxProject = (props) => {
    const { width } = useDimension()

    return <React.Fragment>
        <div className={`pq-knackbox-project container ${width < 768 ? 'px-32px pt-44px' : 'pt-40px'}`}>
            <ProjectHeader
                header={`Portfolio: Knackbox, a children's habit forming app`}
                smallHeader='Portfolio: Knackbox'
                nextLink='/project/mitat'
                nextText='Mitat'
            />
            <div className={`mb-61px ${width < 768 ? 'mt-45px' : 'mt-58px'}`}>
                <div className='row align-items-stretch'>
                    <div className='col-lg-5 mb-3'>
                        <ProjectDescriptionBox heading='BACKGROUND'>
                            <div className='mb-19px'>
                                <span className='fs-16px text-dark-blue fw-medium ff-noto-sans'>Introduction: </span>
                                <span className='fs-14px text-extra-dark-gray fw-regular ff-noto-sans'>This app is based on the fact that it takes 21 days to form a habit.<br />The child uses this app with parental guidance, and earns rewards/stars for completing each task.</span>
                            </div>
                            <div className='mb-19px'>
                                <span className='fs-16px text-dark-blue fw-medium ff-noto-sans'>Inspiration: </span>
                                <span className='fs-14px text-extra-dark-gray fw-regular ff-noto-sans'>Dora the explorer</span>
                            </div>
                            <div>
                                <span className='fs-16px text-dark-blue fw-medium ff-noto-sans'>Target Audience: </span>
                                <span className='fs-14px text-extra-dark-gray fw-regular ff-noto-sans'>Children aged 3-7</span>
                            </div>
                        </ProjectDescriptionBox>
                    </div>
                    <div className='col-lg-7 mb-3'>
                        <ProjectDescriptionBox heading='BRANDING'>
                            <div className='row'>
                                <div className='col-sm-6 col-md-4 mb-3'>
                                    <div className='fs-16px text-kb-dark-blue fw-medium ff-noto-sans mb-16px'>Logo design:</div>
                                    <div className='w-128px'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <Tasks />
                                            <Plus />
                                            <Box />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='fs-14px ff-noto-sans fw-regular text-extra-dark-gray ps-5px'>Tasks</div>
                                            <div className='fs-14px ff-noto-sans fw-regular text-extra-dark-gray pe-10px'>Box</div>
                                        </div>
                                        <div className='text-center'>
                                            <div className='d-flex flex-column align-items-center'>
                                                <ArrowDown />
                                                <Knackbox className='mt-14px' />
                                                <div className='fs-14px mt-4px ff-noto-sans fw-regular text-extra-dark-gray'>Knackbox</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6 col-md-4 mb-3'>
                                    <div className='fs-16px text-kb-dark-blue fw-medium ff-noto-sans mb-16px'>Colour scheme:</div>

                                    <div className='d-flex mb-9px'>
                                        <div className='w-75px px-12px py-2px br-6px text-kb-dark-gray bg-kb-gold fs-12px ff-noto-sans fw-regular me-12px'>#FFD15A</div>
                                        <div className='w-75px px-12px py-2px br-6px text-kb-dark-gray bg-kb-blue fs-12px ff-noto-sans fw-regular'>#BEEAF9</div>
                                    </div>

                                    <div className='d-flex mb-42px'>
                                        <div className='w-75px px-12px py-2px br-6px text-white bg-kb-gray fs-12px ff-noto-sans fw-regular me-12px'>#565656</div>
                                        <div className='w-75px px-12px py-2px br-6px text-kb-dark-gray bg-kb-light-gray fs-12px ff-noto-sans fw-regular'>#DCE4E4</div>
                                    </div>

                                    <div className='mb-12px'>
                                        <span className='fs-16px text-kb-dark-blue fw-medium ff-noto-sans'>Typography: </span>
                                        <span className='fs-16px text-kb-dark-blue fw-regular ff-noto-sans'>Raleway</span>
                                    </div>

                                    <div className='fs-14px text-kb-dark-blue fw-regular ff-noto-sans'>The quick brown fox jumps over the lazy dog</div>
                                </div>

                                <div className='col-md-4 mb-3'>
                                    <div className='fs-16px text-kb-dark-blue fw-medium ff-noto-sans mb-16px'>Custom Illustrations:</div>
                                    <div className='d-flex mb-15px'>
                                        <Elephant className='me-20px' />
                                        <Owl />
                                    </div>
                                    <div className='d-flex'>
                                        <Crocodile className='me-20px' />
                                        <Dog />
                                    </div>
                                </div>
                            </div>
                        </ProjectDescriptionBox>
                    </div>
                </div>
            </div>

            <Newsletter />

            <AppScreens />

        </div>
        <ProjectFooter />
        <GoToTop />
        <ResetSubscribeNewsletterStates />
    </React.Fragment>
}

const Newsletter = () => {
    const { subscribeClickEventHandler, subscribeEmail, subscribeEmailChangeEventHandler, subscribeEmailError, subscribing } = React.useContext<AppContextType | null>(AppContext)!
    const { width } = useDimension()

    return width < 768
        ? <div className='newsletter h-130px mb-60px mt-30px'>
            <div className='d-flex align-items-center justify-content-center h-100'>
                <div>
                    <div className='text-orange ff-dax-pro fw-medium text-uppercase text-center mb-17px fs-16px'>SUBSCRIBE TO THE KNACKBOX NEWSLETTER!</div>
                    <div className='row'>
                        <div className='col-md-6 my-1'>
                            <div className='fs-14px text-extra-dark-gray ff-noto-sans fw-regular me-30px text-center'>Be the first to try Knackbox upon it's release! <br />We'll notify you with the latest news and updates!</div>
                        </div>
                        <div className='col-md-6 my-1'>
                            <Input
                                placeholder='Email Address'
                                onChange={subscribeEmailChangeEventHandler}
                                value={subscribeEmail}
                                errorText={subscribeEmailError}
                                containerClass='mb-16px'
                            />
                            <Button
                                onClick={subscribeClickEventHandler}
                                loading={subscribing}
                                className='w-100 mb-60px'>Subscribe!</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : <div className='newsletter h-130px mb-60px mt-30px'>
            <div className='d-flex align-items-center justify-content-center h-100'>
                <div>
                    <div className='text-orange ff-dax-pro fw-medium text-uppercase mb-17px fs-20px'>SUBSCRIBE TO THE KNACKBOX NEWSLETTER!</div>
                    <div className='row'>
                        <div className='col-md-6 my-1'>
                            <div className='fs-14px text-extra-dark-gray ff-noto-sans fw-regular me-30px'>Be the first to try Knackbox upon it's release! <br />We'll notify you with the latest news and updates!</div>
                        </div>
                        <div className='col-md-6 my-1'>
                            <InputGroup
                                buttonProps={{
                                    children: 'Subscribe!',
                                    onClick: subscribeClickEventHandler
                                }}
                                inputProps={{
                                    placeholder: 'Email Address',
                                    onChange: subscribeEmailChangeEventHandler,
                                    value: subscribeEmail,
                                }}
                                loading={subscribing}
                                errorText={subscribeEmailError}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

const AppScreens = () => {
    const { width } = useDimension()

    return <div>
        <div className={`text-orange ff-dax-pro fw-medium text-uppercase mb-25px text-center ${width < 768 ? 'fs-16px' : 'fs-20px'}`}>APP SCREENS</div>
        {width < 1200
            ? <div className='w-100 h-100'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <div className='vstack gap-3 align-items-center mb-30px'>
                                <Screen1 />
                                <StrokeText>01</StrokeText>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <div className='vstack gap-3 align-items-center mb-30px'>
                                <Screen2 />
                                <StrokeText>02</StrokeText>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <div className='vstack gap-3 align-items-center mb-30px'>
                                <Screen3 />
                                <StrokeText>03</StrokeText>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <div className='vstack gap-3 align-items-center mb-30px'>
                                <Screen4 />
                                <StrokeText>04</StrokeText>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                            <div className='vstack gap-3 align-items-center mb-30px'>
                                <Screen5 />
                                <StrokeText>05</StrokeText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <React.Fragment>
                <div className='w-100 h-100'>
                    <img src={KnackboxAppScreensSvg} alt='' className='w-100 h-100 max-h-500px of-cover op-center' />
                </div>

                <div className='mt-15px mb-80px'>
                    <div className='d-flex align-items-center justify-content-around'>
                        <StrokeText>01</StrokeText>
                        <StrokeText>02</StrokeText>
                        <StrokeText>03</StrokeText>
                        <StrokeText>04</StrokeText>
                        <StrokeText>05</StrokeText>
                    </div>
                </div>
            </React.Fragment>}
    </div>
}

export default KnackBoxProject