const apiUrl = String(process.env.REACT_APP_API_URL)
const emailHost = String(process.env.REACT_APP_EMAIL_HOST)
const emailPort = Number(process.env.REACT_APP_EMAIL_PORT)
const emailSecure = Boolean(process.env.REACT_APP_EMAIL_SECURE)
const emailUser = String(process.env.REACT_APP_EMAIL_USER)
const emailPass = String(process.env.REACT_APP_EMAIL_PASS)
const emailService = String(process.env.REACT_APP_EMAIL_SERVICE)

const config = {
    apiUrl,
    emailHost,
    emailPort,
    emailSecure,
    emailUser,
    emailPass,
    emailService
}

export default config