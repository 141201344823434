import React from 'react'
import { Link } from 'react-router-dom'
import useDimension from '../hooks/useDimension'
import ProjectHeaderNextLink from './ProjectHeaderNextLink'
import { GrayscalePrgmiqLogo, GrayscalePrgmiqLogoHover } from './svg'
import { GrayscalePrgmiqLogoSM } from './svg/GrayscalePrgmiqLogoXS'

interface Props {
    header?: string
    smallHeader?: string
    nextText?: string
    nextLink?: string
}

type PQProjectHeader = React.FC<Props>

const ProjectHeader: PQProjectHeader = (props) => {
    const { width } = useDimension()

    const [isHoverOnLogo, setIsHoverLogo] = React.useState<boolean>(false)

    const mouseEnterOnLogoHandler: React.MouseEventHandler<HTMLAnchorElement> = () => {
        setIsHoverLogo(true)
    }

    const mouseLeaveOnLogoHandler: React.MouseEventHandler<HTMLAnchorElement> = () => {
        setIsHoverLogo(false)
    }

    return <React.Fragment>
        {width < 768
            ? <React.Fragment>
                <div className='pq-project-header hstack align-items-center justify-content-between'>
                    <div className='hstack gap-4'>
                        <Link to='/' className='d-inline-block'>
                            <GrayscalePrgmiqLogoSM />
                        </Link>
                        <div className='header-text fs-16px text-dark-blue ff-dax-pro fw-regular max-w-100px'>{props.smallHeader}</div>
                    </div>
                    <ProjectHeaderNextLink to={props.nextLink ? props.nextLink : '#'}>Next</ProjectHeaderNextLink>
                </div>
            </React.Fragment>
            : <React.Fragment>
                <div className='pq-project-header d-flex align-items-end justify-content-between'>
                    <Link to='/' className='d-inline-block' onMouseEnter={mouseEnterOnLogoHandler} onMouseLeave={mouseLeaveOnLogoHandler}>
                        {isHoverOnLogo
                            ? <GrayscalePrgmiqLogoHover />
                            : <GrayscalePrgmiqLogo />}
                    </Link>
                    {width > 992 && <div className='header-text fs-24px text-dark-blue ff-dax-pro fw-regular'>{props.header}</div>}
                    <ProjectHeaderNextLink to={props.nextLink ? props.nextLink : '#'}>Next: {props.nextText}</ProjectHeaderNextLink>
                </div>
                {width < 992 && <div className='fs-24px text-dark-blue ff-dax-pro fw-regular mt-20px text-center'>{props.header}</div>}
            </React.Fragment>}
    </React.Fragment>
}

export default ProjectHeader