import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQTasks = React.FC<Props>

const defaultProps: Props = {
    id: 'Group_770',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '39.156',
    height: '32.836',
    viewBox: '0 0 39.156 32.836'
}

const Tasks: PQTasks = (props: Props) => {
    return <svg {...props} data-name='Group 770' className={`pq-tasks ${props.className ? props.className : ''}`}>
        <path id='Icon_awesome-tasks' data-name='Icon awesome-tasks' d='M19.478,2.678a1.463,1.463,0,0,0-2.073,0L8,10.654,4.418,7.7a1.463,1.463,0,0,0-2.073,0L.43,9.617a1.463,1.463,0,0,0,0,2.073l6.621,5.78a1.558,1.558,0,0,0,2.147,0L21.54,6.763a1.474,1.474,0,0,0,.011-2.073Z' transform='translate(17.185 -2.248)' fill='#565656' />
        <circle id='Ellipse_57' data-name='Ellipse 57' cx='5.401' cy='5.401' r='5.401' transform='translate(0 4.753)' fill='#565656' />
        <path id='Icon_awesome-tasks-2' data-name='Icon awesome-tasks' d='M19.478,2.678a1.463,1.463,0,0,0-2.073,0L8,10.654,4.418,7.7a1.463,1.463,0,0,0-2.073,0L.43,9.617a1.463,1.463,0,0,0,0,2.073l6.621,5.78a1.558,1.558,0,0,0,2.147,0L21.54,6.763a1.474,1.474,0,0,0,.011-2.073Z' transform='translate(17.185 14.937)' fill='#565656' />
        <circle id='Ellipse_58' data-name='Ellipse 58' cx='5.401' cy='5.401' r='5.401' transform='translate(0 22.035)' fill='#565656' />
    </svg>
}

Tasks.defaultProps = defaultProps

export { Tasks }