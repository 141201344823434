import React from 'react'
import MitatLg from '../assets/images/mitat-lg.png'
import GoToTop from '../components/GoToTop'
import ProjectFooter from '../components/ProjectFooter'
import ProjectHeader from '../components/ProjectHeader'
import useDimension from '../hooks/useDimension'

interface Props { }

type PQMitatProject = React.FC<Props>

const MitatProject: PQMitatProject = (props) => {
    const { width } = useDimension()

    return <React.Fragment>
        <div className={`pq-mitat-project container ${width < 768 ? 'px-32px pt-44px' : 'pt-40px'}`}>
            <ProjectHeader
                header='Mitat'
                nextLink='/project/knackbox'
                nextText='Knackbox'
                smallHeader='Portfolio: Mitat'
            />
            <div className='mt-50px'>
                <img src={MitatLg} alt='Mitat' className='w-100 h-100 of-cover op-center' />
            </div>
        </div>
        <ProjectFooter />
        <GoToTop />
    </React.Fragment>
}

export default MitatProject