import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQPlus = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '14.253',
    height: '14.253',
    viewBox: '0 0 14.253 14.253',
}

const Plus: PQPlus = (props: Props) => {
    return <svg {...props} className={`pq-plus ${props.className ? props.className : ''}`}>
        <g id='Icon_feather-plus' data-name='Icon feather-plus' transform='translate(1.5 1.5)'>
            <path id='Path_7070' data-name='Path 7070' d='M18,7.5V18.753' transform='translate(-12.373 -7.5)' fill='none' stroke='#cacaca' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' />
            <path id='Path_7071' data-name='Path 7071' d='M7.5,18H18.753' transform='translate(-7.5 -12.373)' fill='none' stroke='#cacaca' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' />
        </g>
    </svg>
}

Plus.defaultProps = defaultProps

export { Plus }