import React from 'react'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    errorText?: string
    innerRef?: React.LegacyRef<HTMLInputElement>
    containerClass?: string
}

type PQInput = React.FC<Props>

const Input: PQInput = ({ errorText, innerRef, containerClass, ...props }) => {
    return <div className={containerClass}>
        <input {...props} className={`pq-input ${errorText && errorText !== '' ? 'border-orange' : ''} ${props.className ? props.className : ''}`} ref={innerRef} />
        <span className='d-inline-block text-orange fs-12px'>{errorText}</span>
    </div>
}

export default Input