import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

type PQHorizontalLine = React.FC<Props>

const HorizontalLine: PQHorizontalLine = (props) => {
    const { width } = useDimension()

    return width >= 768
        ? <div {...props} className={`pq-horizontal-line w-40 mx-auto border border-1 border-light-gray ${props.className ? props.className : ''}`} />
        : <div className='h-30px' />
}

export default HorizontalLine