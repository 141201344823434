import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQPragmiqLogo = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '114',
    height: '85',
    viewBox: '0 0 115 86'
}

const PragmiqLogo: PQPragmiqLogo = (props: Props) => {
    return <svg {...props} className={`pq-logo ${props.className ? props.className : ''}`}>
        <g id='Group_37' data-name='Group 37' transform='translate(-146.585 -1041.313)'>
            <g id='Group_35' data-name='Group 35' transform='translate(147.585 1042.313)'>
                <g id='Group_15' data-name='Group 15' transform='translate(0 0)'>
                    <g id='Group_3' data-name='Group 3' transform='translate(0 0)'>
                        <g id='Group_1' data-name='Group 1'>
                            <path id='Path_10678' data-name='Path 10678' d='M0,0H21.194c11.43,0,25.769,24.043,10.151,40.743S22.432,66,22.432,66H0Z' transform='translate(0 5)' fill='#ced1e8' />
                            <path id='Union_1' data-name='Union 1' d='M18.691,70.675H0V0H31.083c11.244,0,20.36,8.839,20.36,19.742v13.7C51.437,46.59,37.511,50.369,31.337,53.809s-6.656,8.567-6.656,12.784v4.081Z' fill='none' stroke='#0b1560' strokeWidth='2' />
                            <g id='Group_28' data-name='Group 28' transform='translate(1.342 28.029)'>
                                <path id='Path_2' data-name='Path 2' d='M12.14,38.038V-4.607H6.149' transform='translate(-6.149 4.607)' fill='none' stroke='#0b1560' strokeWidth='2' />
                            </g>
                        </g>
                        <line id='Line_203' data-name='Line 203' x2='21.814' transform='translate(1.625 76.169)' fill='none' stroke='#0b1560' strokeWidth='2' />
                    </g>
                </g>
            </g>
            <g id='Group_40' data-name='Group 40' transform='translate(-0.175 -0.289)'>
                <path id='Path_198' data-name='Path 198' d='M-33.246-16.38a10.656,10.656,0,0,0-1.405-.09,12.22,12.22,0,0,0-5.5,1.14V0h2.631V-14.25a4.491,4.491,0,0,1,2.033-.42,4.729,4.729,0,0,1,1.645.27ZM-20.06-.84V-11.22c0-3.6-2.153-5.25-5.651-5.25a11.258,11.258,0,0,0-4.9,1.17l.628,1.62A8.939,8.939,0,0,1-25.95-14.7c2.243,0,3.439.99,3.439,3.21v.99c-5.2.51-9.03,2.01-9.03,5.91,0,3.21,2.272,4.89,6.13,4.89A11.722,11.722,0,0,0-20.06-.84Zm-2.452-.93a6.163,6.163,0,0,1-2.661.54c-2.512,0-3.857-1.23-3.857-3.42,0-2.85,2.422-3.99,6.518-4.47ZM19.521.3V-10.95c0-3.09-1.734-5.22-5.681-5.22a6.215,6.215,0,0,0-4.635,1.89c-1.047-1.23-2.422-1.89-4.844-1.89A13.348,13.348,0,0,0-1.17-15.03V.3h2.6V-13.95a7.157,7.157,0,0,1,3.02-.54c2.392,0,3.409,1.23,3.409,3.09V.3h2.6V-12.96a3.994,3.994,0,0,1,3.14-1.53c2.362,0,3.319,1.38,3.319,3.51V.3Zm7.326-20.73a1.537,1.537,0,0,0-1.585-1.47,1.537,1.537,0,0,0-1.585,1.47,1.537,1.537,0,0,0,1.585,1.47A1.537,1.537,0,0,0,26.847-20.43ZM26.578.3V-15.87H23.947V.3Z' transform='translate(219.912 1119.501)' fill='#0b1560' />
                <path id='Path_10665' data-name='Path 10665' d='M3.019,5.457C4.2,7.672,5.625,8.244,9.516,8.066s4.49-2.935,4.49-4V-15.879a32.615,32.615,0,0,0-4.9-.591c-6.573,0-6.084,3.362-6.084,8.574,0,4.911-.108,8.208,5.036,8.208,1.357,0,3.269-.06,3.269-.06V2.788s.2,3.156-1.562,3.421a5.346,5.346,0,0,1-2.995-.157,3.012,3.012,0,0,1-1.474-1.73Zm8.3-6.832H8.733C5.562-1.376,5.291-3.8,5.291-7.9c0-4.248.045-6.977,3.658-6.977a21.672,21.672,0,0,1,2.375.215Z' transform='translate(200.753 1119.505)' fill='#0b1560' />
                <path id='Path_10667' data-name='Path 10667' d='M14.006,6.07V-15.879a32.615,32.615,0,0,0-4.9-.591c-6.573,0-6.084,3.362-6.084,8.574,0,4.911-.108,8.208,5.036,8.208,1.357,0,3.269-.06,3.269-.06V6.07ZM11.323-1.376H8.733C5.562-1.376,5.291-3.8,5.291-7.9c0-4.248.045-6.977,3.658-6.977a21.672,21.672,0,0,1,2.375.215Z' transform='translate(247.753 1119.505)' fill='#0b1560' />
            </g>
        </g>
    </svg>
}

PragmiqLogo.defaultProps = defaultProps

export { PragmiqLogo }