import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQGrayscalePrgmiqLogoSM = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '33',
    height: '49',
    viewBox: '0 0 33 49'
}

const GrayscalePrgmiqLogoSM: PQGrayscalePrgmiqLogoSM = (props: Props) => {
    return <svg {...props} className={`pq-logo ${props.className ? props.className : ''}`}>
        <g id='Group_37' data-name='Group 37' transform='translate(-147.085 -1041.813)'>
            <g id='Group_35' data-name='Group 35' transform='translate(147.585 1042.313)'>
                <g id='Group_15' data-name='Group 15' transform='translate(0 0)'>
                    <g id='Group_3' data-name='Group 3' transform='translate(0 0)'>
                        <g id='Group_1' data-name='Group 1'>
                            <path id='Path_10678' data-name='Path 10678'
                                d='M0,0H13.2c7.118,0,16.048,15.179,6.322,25.722S13.97,41.667,13.97,41.667H0Z'
                                transform='translate(0 3.114)' fill='#fff' />
                            <path id='Union_1' data-name='Union 1'
                                d='M11.627,44.578H0V0H19.335A12.56,12.56,0,0,1,32,12.452V21.1c0,8.291-8.666,10.675-12.507,12.845s-4.141,5.4-4.141,8.064v2.574Z'
                                fill='none' stroke='#0b1560' stroke-width='1' />
                            <g id='Group_28' data-name='Group 28' transform='translate(0.213 18.02)'>
                                <path id='Path_2' data-name='Path 2' d='M9.88,21.951V-4.607H6.149'
                                    transform='translate(-6.149 4.607)' fill='none' stroke='#0b1560' stroke-width='1' />
                            </g>
                        </g>
                        <line id='Line_203' data-name='Line 203' x2='13.585' transform='translate(1.012 48)' fill='none'
                            stroke='#0b1560' stroke-width='1' />
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

GrayscalePrgmiqLogoSM.defaultProps = defaultProps

export { GrayscalePrgmiqLogoSM }




