import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQGrayscalePrgmiqLogo = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '66.75',
    height: '49.961',
    viewBox: '0 0 66.75 49.961'
}

const GrayscalePrgmiqLogo: PQGrayscalePrgmiqLogo = (props: Props) => {
    return <svg {...props} className={`pq-grayscale-prgmiq-logo ${props.className ? props.className : ''}`}>
        <g id='Component_16_1' data-name='Component 16 – 1' transform='translate(0.75 0.75)'>
            <path id='Path_10678' data-name='Path 10678' d='M0,0H11.409c6.153,0,13.871,12.942,5.464,21.931s-4.8,13.6-4.8,13.6H0Z' transform='translate(0 5)' fill='#ced1e8' opacity='0' />
            <g id='Group_35' data-name='Group 35' transform='translate(0 0)'>
                <g id='Group_15' data-name='Group 15' transform='translate(0 0)'>
                    <g id='Group_3' data-name='Group 3' transform='translate(0 0)'>
                        <g id='Group_1' data-name='Group 1'>
                            <path id='Union_1' data-name='Union 1' d='M10.821,40.917H0V0H18A11.612,11.612,0,0,1,29.782,11.43v7.933c0,7.61-8.065,9.8-11.64,11.79s-3.854,4.96-3.854,7.4v2.363Z' fill='none' stroke='#0b1560' strokeWidth='1.5' />
                            <g id='Group_28' data-name='Group 28' transform='translate(0.777 16.227)'>
                                <path id='Path_2' data-name='Path 2' d='M9.617,20.082V-4.607H6.149' transform='translate(-6.149 4.607)' fill='none' stroke='#0b1560' strokeWidth='1.5' />
                            </g>
                        </g>
                        <line id='Line_203' data-name='Line 203' x2='12.629' transform='translate(0.941 44.098)' fill='none' stroke='#0b1560' strokeWidth='1.5' />
                    </g>
                </g>
            </g>
            <g id='Group_40' data-name='Group 40' transform='translate(18.526 31.842)'>
                <path id='Path_198' data-name='Path 198' d='M-36.154-18.7a6.169,6.169,0,0,0-.814-.052,7.075,7.075,0,0,0-3.185.66v8.875h1.523v-8.25a2.6,2.6,0,0,1,1.177-.243,2.738,2.738,0,0,1,.952.156Zm7.634,9v-6.009c0-2.084-1.246-3.039-3.272-3.039a6.518,6.518,0,0,0-2.839.677l.364.938a5.175,5.175,0,0,1,2.337-.591c1.3,0,1.991.573,1.991,1.858v.573c-3.012.3-5.228,1.164-5.228,3.422,0,1.858,1.316,2.831,3.549,2.831A6.787,6.787,0,0,0-28.52-9.707Zm-1.419-.538a3.568,3.568,0,0,1-1.541.313c-1.454,0-2.233-.712-2.233-1.98,0-1.65,1.4-2.31,3.774-2.588ZM-5.6-9.047v-6.513c0-1.789-1-3.022-3.289-3.022a3.6,3.6,0,0,0-2.683,1.094,3.308,3.308,0,0,0-2.8-1.094,7.728,7.728,0,0,0-3.2.66v8.875h1.506V-17.3a4.143,4.143,0,0,1,1.748-.313c1.385,0,1.973.712,1.973,1.789v6.774h1.506v-7.677a2.312,2.312,0,0,1,1.818-.886c1.368,0,1.922.8,1.922,2.032v6.531Zm4.241-12a.89.89,0,0,0-.917-.851.89.89,0,0,0-.917.851.89.89,0,0,0,.917.851A.89.89,0,0,0-1.363-21.049Zm-.156,12v-9.362H-3.043v9.362Z' transform='translate(40.153 21.9)' fill='#0b1560' />
                <path id='Path_10665' data-name='Path 10665' d='M3.015-3.776C3.7-2.493,4.524-2.162,6.777-2.265s2.6-1.7,2.6-2.316V-16.128a18.883,18.883,0,0,0-2.839-.342c-3.805,0-3.522,1.946-3.522,4.964,0,2.843-.063,4.752,2.915,4.752.786,0,1.892-.035,1.892-.035v1.468s.115,1.827-.9,1.981a3.1,3.1,0,0,1-1.734-.091,1.744,1.744,0,0,1-.853-1ZM7.823-7.731h-1.5c-1.836,0-1.993-1.4-1.993-3.775,0-2.459.026-4.039,2.118-4.039a12.547,12.547,0,0,1,1.375.124Z' transform='translate(10.887 19.616)' fill='#0b1560' />
                <path id='Path_10667' data-name='Path 10667' d='M9.376-3.421V-16.128a18.883,18.883,0,0,0-2.839-.342c-3.805,0-3.522,1.946-3.522,4.964,0,2.843-.063,4.752,2.915,4.752.786,0,1.892-.035,1.892-.035v3.368ZM7.823-7.731h-1.5c-1.836,0-1.993-1.4-1.993-3.775,0-2.459.026-4.039,2.118-4.039a12.547,12.547,0,0,1,1.375.124Z' transform='translate(38.097 19.616)' fill='#0b1560' />
            </g>
        </g>
    </svg>
}

GrayscalePrgmiqLogo.defaultProps = defaultProps

export { GrayscalePrgmiqLogo }
