import { nanoid } from 'nanoid'
import React from 'react'
import { X } from '../svg'
import './toast.scss'

export enum ToastTypes {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

type ToastType = ToastTypes.SUCCESS | ToastTypes.ERROR
type Toast = (text: string, type?: ToastType) => void
type RemoveToast = (key: string) => void
type UseToast = () => Toast

interface Props {
    children: React.ReactNode
}

interface CloseToast {
    onClose: () => void
}


interface ToastItemType {
    text: string
    type: ToastType
}

interface Message extends ToastItemType {
    key: string
}

interface ToastContextValue {
    toast: Toast
}

const ToastContext = React.createContext<ToastContextValue | null>(null)

const ToastProvider: React.FC<Props> = ({ children }) => {
    const [messageList, setMessageList] = React.useState<Array<Message>>([])

    const setToast: Toast = (text, type = ToastTypes.SUCCESS) => {
        const key = nanoid()

        setMessageList(prev => {
            return [{
                key: key,
                text: text,
                type: type
            }, ...prev].slice(0, 4)
        })

        setTimeout(() => {
            removeToast(key)
        }, 10000)
    }

    const removeToast: RemoveToast = (key) => {
        setMessageList(prev => {
            return prev.filter(p => p.key !== key)
        })
    }

    return <ToastContext.Provider value={{ toast: setToast }}>
        {messageList.length > 0 && <div className='pq-toast-container overflow-hidden px-3 py-2'>
            {messageList.map((msg) => {
                return <ToastItem
                    key={msg.key}
                    text={msg.text}
                    type={msg.type}
                    onClose={() => removeToast(msg.key)}
                />
            })}
        </div>}
        {children}
    </ToastContext.Provider>
}

const ToastItem: React.FC<ToastItemType & CloseToast> = ({ text, type, onClose }) => {
    const [animationClass, setAnimationClass] = React.useState('slide-in')

    const closeToastClickEventHandler = () => {
        setAnimationClass('slide-out')
        setTimeout(() => {
            onClose()
        }, 200)
    }

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAnimationClass('slide-out')
        }, 9800)

        return () => clearTimeout(timeoutId)
    }, [])

    return <div className={`${animationClass} ${type === ToastTypes.SUCCESS ? 'bg-toast-green' : 'bg-danger'} fs-13px ff-dax-pro text-white rounded my-2 px-15px py-10px shadow-sm hstack justify-content-between`}>
        <span>{text}</span>
        <X className='ms-10px d-inline-block fs-13px cursor-pointer' onClick={closeToastClickEventHandler} />
    </div>
}

export const useToast: UseToast = () => {
    const { toast } = React.useContext(ToastContext)!
    return toast
}

export default ToastProvider