import React from 'react'
import Spinner from './Spinner'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    loading?: boolean
}

type PQButton = React.FC<Props>

const Button: PQButton = ({ loading, ...props }) => {
    return loading
        ? <button {...props} className={`pq-btn-orange ${props.className ? props.className : ''}`} onClick={e => e.preventDefault()}>
            <Spinner />
        </button>
        : <button {...props} className={`pq-btn-orange ${props.className ? props.className : ''}`} />

}

export default Button