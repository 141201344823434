import React from 'react'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> { }

type PQStrokeText = React.FC<Props>

const StrokeText: PQStrokeText = (props) => {
    return <span {...props} className={`pq-stoke-text ${props.className ? props.className : ''}`} />
}

export default StrokeText