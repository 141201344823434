import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQScriptBoard = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '42',
    height: '42',
    viewBox: '0 0 42 42',
}

const ScriptBoard: PQScriptBoard = (props: Props) => {
    return <svg {...props} className={`pq-script-board ${props.className ? props.className : ''}`}>
        <g id='Group_3617' data-name='Group 3617' transform='translate(-422 166)'>
            <path id='Path_7478' data-name='Path 7478' d='M443-166a21.024,21.024,0,0,0-21,21,21.024,21.024,0,0,0,21,21,21.024,21.024,0,0,0,21-21A21.024,21.024,0,0,0,443-166Zm0,40.6A19.622,19.622,0,0,1,423.4-145,19.622,19.622,0,0,1,443-164.6,19.622,19.622,0,0,1,462.6-145,19.622,19.622,0,0,1,443-125.4Z' fill='#ff725e' />
            <path id='Path_7479' data-name='Path 7479' d='M453.832-143.667h-7.56a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7h7.56a.7.7,0,0,0,.7-.7A.7.7,0,0,0,453.832-143.667Zm0,3.427h-7.56a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7h7.56a.7.7,0,0,0,.7-.7A.7.7,0,0,0,453.832-140.24Zm0,3.427h-7.56a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7h7.56a.7.7,0,0,0,.7-.7A.7.7,0,0,0,453.832-136.814Zm0,3.427h-7.56a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7h7.56a.7.7,0,0,0,.7-.7A.7.7,0,0,0,453.832-133.387Z' transform='translate(-7.052 -6.672)' fill='#ff725e' />
            <path id='Path_7480' data-name='Path 7480' d='M456.034-153.964h-4.545v-1.517a.7.7,0,0,0-.7-.7h-4.877a.7.7,0,0,0-.7.7v1.517h-4.546a.7.7,0,0,0-.7.7v22.3a.694.694,0,0,0,.2.493.7.7,0,0,0,.493.2h15.361a.7.7,0,0,0,.7-.7v-22.295A.7.7,0,0,0,456.034-153.964Zm-9.422-.821H450.1v.821h-3.484Zm-.7,2.214h5.721v.682h-6.565v-.682Zm9.422,20.9H441.37v-20.9h2.308v1.379a.7.7,0,0,0,.7.7h7.958a.7.7,0,0,0,.7-.7v-1.379h2.308v20.9Z' transform='translate(-5.354 -2.894)' fill='#ff725e' />
        </g>
    </svg>
}

ScriptBoard.defaultProps = defaultProps

export { ScriptBoard }