import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQX = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '15',
    height: '15',
    viewBox: '0 0 24 24',
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: '2.5',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
}

const X: PQX = (props: Props) => {
    return <svg {...props} className={`pq-x ${props.className ? props.className : ''}`}>
        <line x1='18' y1='6' x2='6' y2='18' />
        <line x1='6' y1='6' x2='18' y2='18' />
    </svg>
}

X.defaultProps = defaultProps

export { X }