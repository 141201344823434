import React from 'react'
import { AppContext, AppContextType } from './context/AppProvider'

const ResetSubscribeNewsletterStates = () => {
    const { setSubscribeEmail, setSubscribeEmailError, setSubscribing } = React.useContext<AppContextType | null>(AppContext)!

    React.useEffect(() => {
        setSubscribeEmail('')
        setSubscribeEmailError('')
        setSubscribing(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <React.Fragment />
}

export default ResetSubscribeNewsletterStates