import React from 'react'
import { Link } from 'react-router-dom'
import useDimension from '../hooks/useDimension'

interface Props {
    img: string
    alt?: string
    to: string
    title?: string
    subTitle?: string
    slideUpText: React.ReactNode
}

type PQProjectContainer = React.FC<Props>

const ProjectContainer: PQProjectContainer = (props) => {
    const { width } = useDimension()
    const [isHover, setIsHover] = React.useState<boolean>(false)

    const onMouseEnter: React.MouseEventHandler<HTMLAnchorElement> = () => {
        setIsHover(true)
    }

    const onMouseLeave: React.MouseEventHandler<HTMLAnchorElement> = () => {
        setIsHover(false)
    }

    return <Link to={props.to} className={`pq-project-container overflow-hidden position-relative max-h-500px rounded d-block ${width < 768 ? 'mb-24px' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <img src={props.img} alt={props.alt} className='w-100 h-100 max-h-500px of-cover op-center' />
        {width < 575 && <div className='position-absolute top-0 start-0 pt-12px px-10px'>
            <div className='text-dark-blue fs-14px ff-noto-sans fw-500 text-uppercase'>{props.title}</div>
            <div className='text-extra-dark-gray fs-12px ff-noto-sans text-truncate'>{props.subTitle}</div>
        </div>}
        {isHover && <React.Fragment>
            <div className='pq-transparent-container w-100 h-100 position-absolute top-0 start-0 bg-extra-dark-transparent' />
            <div className='pq-slide-up-container position-absolute w-100 h-100 d-flex align-items-center justify-content-center text-white text-center ff-dax-pro fs-18px'>{props.slideUpText}</div>
        </React.Fragment>}
    </Link>
}

export default ProjectContainer