import React from 'react'
import useDimension from '../hooks/useDimension'
import Button from './Button'
import { AppContext, AppContextType } from './context/AppProvider'
import { IntroImage, PragmiqLogo } from './svg'
import { PragmiqLogoSM } from './svg/PragmiqLogoSM'

interface Props { }

type PQIntro = React.FC<Props>

const Intro: PQIntro = () => {
  const { width } = useDimension();

  const { registerInterestClickHandler } =
    React.useContext<AppContextType | null>(AppContext)!;

  return (
    <div className={`position-relative ${width < 768 ? "pb-48px" : ""}`}>
      {width < 768 ? <PragmiqLogoSM /> : <PragmiqLogo />}
      {/* <div
        className={`ff-noto-sans fw-regular ${
          width < 768
            ? "text-cold-purple fs-12px mt-12px"
            : "text-blue fs-18px mt-18px"
        }`}
      >
        Pragmatic solutions
      </div> */}
      <div
        className={`ff-dax-pro fw-regular ${
          width < 1200 ? "mt-50px" : "mt-122px"
        } ${width > 992 && width < 1200 ? "max-w-500px" : ""} ${
          width < 768 ? "text-white fs-30px mt-55px" : "text-blue fs-36px"
        }`}
      >
        We create pragmatic solutions <br /> through design and technology !!
      </div>
      <div className="mt-40px">
        <Button
          className={width < 768 ? "w-100" : "w-300px"}
          onClick={registerInterestClickHandler}
        >
          Say Hello !
        </Button>
      </div>
      <div
        className="d-none d-lg-block position-absolute top-0 end-0"
        style={{ zIndex: -1 }}
      >
        <IntroImage />
      </div>
    </div>
  );
};

export default Intro