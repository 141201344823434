import React from 'react'
import AppProvider from './context/AppProvider'
import Router from './Router'
import ToastProvider from './toast/ToastProvider'

const App = () => {
    return <ToastProvider>
        <AppProvider>
            <Router />
        </AppProvider>
    </ToastProvider>
}

export default App
