import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQPragmiqLogoSM = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '82',
    height: '61',
    viewBox: '0 0 82 61'
}

const PragmiqLogoSM: PQPragmiqLogoSM = (props: Props) => {
    return <svg {...props} className={`pq-logo ${props.className ? props.className : ''}`}>
        <g id='Group_37' data-name='Group 37' transform='translate(-146.585 -1041.313)'>
            <g id='Group_35' data-name='Group 35' transform='translate(147.585 1042.313)'>
                <g id='Group_15' data-name='Group 15' transform='translate(0 0)'>
                    <g id='Group_3' data-name='Group 3' transform='translate(0 0)'>
                        <g id='Group_1' data-name='Group 1'>
                            <path id='Path_10678' data-name='Path 10678'
                                d='M0,0H15.059c8.121,0,18.309,16.9,7.213,28.631S15.938,46.38,15.938,46.38H0Z'
                                transform='translate(0 3.553)' fill='rgba(255,255,255,0.2)' />
                            <path id='Union_1' data-name='Union 1'
                                d='M12.908,49.7H0V0H21.466a13.973,13.973,0,0,1,14.06,13.884V23.52c0,9.244-9.621,11.9-13.885,14.321s-4.6,6.025-4.6,8.99V49.7Z'
                                fill='none' stroke='#fff' stroke-width='2' />
                            <g id='Group_28' data-name='Group 28' transform='translate(0.243 19.4)'>
                                <path id='Path_2' data-name='Path 2' d='M10.406,25.694v-30.3H6.149'
                                    transform='translate(-6.149 4.607)' fill='none' stroke='#fff' stroke-width='2' />
                            </g>
                        </g>
                        <line id='Line_203' data-name='Line 203' x2='15.499' transform='translate(1.155 53.605)' fill='none'
                            stroke='#fff' stroke-width='2' />
                    </g>
                </g>
            </g>
            <g id='Group_40' data-name='Group 40' transform='translate(170.321 1080.997)'>
                <path id='Path_198' data-name='Path 198'
                    d='M-35.245-17.978a7.571,7.571,0,0,0-1-.064,8.683,8.683,0,0,0-3.909.81V-6.339h1.87V-16.464a3.191,3.191,0,0,1,1.445-.3,3.36,3.36,0,0,1,1.168.192Zm9.369,11.042v-7.375c0-2.558-1.53-3.73-4.015-3.73a8,8,0,0,0-3.484.831l.446,1.151a6.351,6.351,0,0,1,2.868-.725c1.593,0,2.443.7,2.443,2.281v.7c-3.7.362-6.416,1.428-6.416,4.2,0,2.281,1.615,3.474,4.355,3.474A8.329,8.329,0,0,0-25.876-6.936ZM-27.618-7.6a4.379,4.379,0,0,1-1.891.384c-1.785,0-2.741-.874-2.741-2.43,0-2.025,1.721-2.835,4.631-3.176ZM2.247-6.126V-14.12c0-2.2-1.232-3.709-4.037-3.709a4.416,4.416,0,0,0-3.293,1.343,4.06,4.06,0,0,0-3.442-1.343,9.484,9.484,0,0,0-3.93.81V-6.126h1.848V-16.251a5.085,5.085,0,0,1,2.146-.384c1.7,0,2.422.874,2.422,2.2v8.313H-4.19v-9.422a2.838,2.838,0,0,1,2.231-1.087c1.678,0,2.358.981,2.358,2.494v8.015ZM7.452-20.856A1.092,1.092,0,0,0,6.326-21.9,1.092,1.092,0,0,0,5.2-20.856a1.092,1.092,0,0,0,1.126,1.044A1.092,1.092,0,0,0,7.452-20.856ZM7.261-6.126V-17.616H5.392V-6.126Z'
                    transform='translate(40.153 21.9)' fill='#fff' />
                <path id='Path_10665' data-name='Path 10665'
                    d='M3.016-.89C3.853.684,4.868,1.09,7.633.963s3.19-2.085,3.19-2.842V-16.05a23.174,23.174,0,0,0-3.484-.42c-4.67,0-4.323,2.389-4.323,6.092,0,3.489-.077,5.832,3.578,5.832.964,0,2.323-.043,2.323-.043v1.8S9.058-.544,7.807-.356A3.8,3.8,0,0,1,5.679-.468,2.14,2.14,0,0,1,4.632-1.7Zm5.9-4.855H7.076c-2.253,0-2.445-1.722-2.445-4.633,0-3.018.032-4.957,2.6-4.957a15.4,15.4,0,0,1,1.687.153Z'
                    transform='translate(14.045 20.331)' fill='#fff' />
                <path id='Path_10667' data-name='Path 10667'
                    d='M10.823-.455V-16.05a23.174,23.174,0,0,0-3.484-.42c-4.67,0-4.323,2.389-4.323,6.092,0,3.489-.077,5.832,3.578,5.832.964,0,2.323-.043,2.323-.043V-.455ZM8.917-5.745H7.076c-2.253,0-2.445-1.722-2.445-4.633,0-3.018.032-4.957,2.6-4.957a15.4,15.4,0,0,1,1.687.153Z'
                    transform='translate(47.44 20.331)' fill='#fff' />
            </g>
        </g>
    </svg>
}

PragmiqLogoSM.defaultProps = defaultProps

export { PragmiqLogoSM }