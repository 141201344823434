import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQMegaphone = React.FC<Props>

const defaultProps: Props = {
    id: 'Group_3599',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '45',
    height: '32',
    viewBox: '0 0 45 32'
}

const Megaphone: PQMegaphone = (props: Props) => {
    return <svg {...props} data-name='Group 3599' className={`pq-megaphone ${props.className ? props.className : ''}`}>
        <path id='Path_5854' data-name='Path 5854' d='M540.167,180.259a38.664,38.664,0,0,1-.888,8.729,15.328,15.328,0,0,1-.964,2.952,6.52,6.52,0,0,1-.6,1.038c.076-.106-.07.086-.1.116-.049.059-.1.116-.155.172s-.1.1-.157.15c-.092.082.094-.073,0,0-.035.027-.071.053-.107.078a1.7,1.7,0,0,1-.163.1c-.031.016-.133.06.015,0-.027.012-.055.022-.083.032a1.265,1.265,0,0,1-.168.046c-.106.022.134-.015.026,0-.038,0-.076.005-.114.006l.264.075a.942.942,0,0,0-.227-.083l-.139-.02q.2.045.125.014l-.166-.067-.513-.208c-.139-.056.153.107.008-.025-.059-.054-.115-.112-.169-.172s-.113-.13-.166-.2c-.01-.013-.1-.133-.027-.034-.037-.051-.072-.1-.107-.156a9.578,9.578,0,0,1-1.158-2.721,34.251,34.251,0,0,1-1.168-8.984,39.775,39.775,0,0,1,.824-9.316,15.55,15.55,0,0,1,1.04-3.242c.093-.2.2-.389.305-.577.05-.086.1-.17.158-.254.026-.04.053-.079.081-.118s.036-.051,0,0l.055-.071a1.389,1.389,0,0,1,1.011-.666c.028,0,.057,0,.085.005.108.009-.133-.023-.026,0a1.249,1.249,0,0,1,.168.046c.028.009.056.021.083.032-.147-.06-.047-.02-.015,0a1.723,1.723,0,0,1,.19.116c.027.019.054.039.08.059.1.073-.093-.079,0,0,.055.047.107.1.157.15s.1.113.155.172c.026.031.051.062.076.094.092.116-.009-.017.044.057a8.283,8.283,0,0,1,1.1,2.356,30.814,30.814,0,0,1,1.272,7.978c.039.783.055,1.567.056,2.35a.523.523,0,1,0,1.045,0,40.4,40.4,0,0,0-.892-8.877,17.328,17.328,0,0,0-1.012-3.162,5.376,5.376,0,0,0-1.286-1.919,1.854,1.854,0,0,0-2.407-.169,5.129,5.129,0,0,0-1.531,2.17,25.6,25.6,0,0,0-1.714,8.1,46.558,46.558,0,0,0,.234,9.877,27.759,27.759,0,0,0,.825,4.092,11.355,11.355,0,0,0,1.21,2.9,4.2,4.2,0,0,0,.744.914,3.418,3.418,0,0,0,1.038.516l.048.019h0q.289.051-.022-.027a.685.685,0,0,0,.377.069,1.737,1.737,0,0,0,.475-.1,2.525,2.525,0,0,0,.851-.564,6.084,6.084,0,0,0,1.264-2.056,18.73,18.73,0,0,0,.983-3.271,40.811,40.811,0,0,0,.811-8.509A.523.523,0,1,0,540.167,180.259Z' transform='translate(-507.212 -165.765)' fill='#ff725e' />
        <path id='Path_5855' data-name='Path 5855' d='M172.02,199.839l-4.616-1.751-8.97-3.4-6.007-2.278-2.366-.9.382.525V181.789l-.382.525,4.273-1.621,8.846-3.355,7.46-2.83,1.355-.514-.506-.91a5.94,5.94,0,0,0-1.358,2.159A19.28,19.28,0,0,0,169.1,178.8a42.449,42.449,0,0,0-.711,9.571,36.959,36.959,0,0,0,.97,7.8,15.89,15.89,0,0,0,.962,2.828,5.242,5.242,0,0,0,1.2,1.752.513.513,0,0,0,.735,0,.565.565,0,0,0,0-.77,3.208,3.208,0,0,1-.336-.349c-.028-.034-.055-.068-.082-.1-.092-.118.062.088-.027-.036-.065-.091-.126-.186-.185-.282a8.248,8.248,0,0,1-.589-1.186,19.528,19.528,0,0,1-.983-3.569,42,42,0,0,1-.6-9.547,34.6,34.6,0,0,1,.937-7.066,14.436,14.436,0,0,1,.838-2.486,4.253,4.253,0,0,1,1-1.5c.387-.351-.021-1.094-.506-.91l-4.273,1.621L158.6,177.92l-7.46,2.83-1.355.514a.554.554,0,0,0-.382.525v10.246a.554.554,0,0,0,.382.525l4.295,1.629,8.279,3.14,7.876,2.988,1.51.573a.519.519,0,0,0,.64-.38A.558.558,0,0,0,172.02,199.839Z' transform='translate(-143.144 -172.521)' fill='#ff725e' />
        <path id='Path_5856' data-name='Path 5856' d='M44.2,218.727v10.688l.579-.571H40.2a1.108,1.108,0,0,1-1.062-.8,4.566,4.566,0,0,1-.039-1.207v-6.413a1.12,1.12,0,0,1,1.2-1.128h4.477a.571.571,0,0,0,0-1.143H40.2a2.293,2.293,0,0,0-1.83.927,2.823,2.823,0,0,0-.429,1.817v6.638a2.347,2.347,0,0,0,1.182,2.178,2.842,2.842,0,0,0,1.422.27h4.233a.581.581,0,0,0,.579-.571V218.727A.58.58,0,0,0,44.2,218.727Zm22.282,5.345a4.1,4.1,0,0,1-.748,2.408,2.265,2.265,0,0,1-.747.643,1.725,1.725,0,0,1-.935.179l.579.571a46.557,46.557,0,0,1-.006-7.607l-.579.571a1.865,1.865,0,0,1,.884.152,2.345,2.345,0,0,1,.808.677,3.917,3.917,0,0,1,.744,2.406.58.58,0,0,0,1.158,0,5.127,5.127,0,0,0-1.04-3.158A3.384,3.384,0,0,0,65.516,220a3.078,3.078,0,0,0-1.468-.308.585.585,0,0,0-.579.571,46.563,46.563,0,0,0,.006,7.607.586.586,0,0,0,.579.571,3.216,3.216,0,0,0,1.4-.277,3.173,3.173,0,0,0,1.1-.882,5.087,5.087,0,0,0,1.084-3.213A.58.58,0,0,0,66.484,224.072Zm10.859.484h5.041a.571.571,0,0,0,0-1.143H77.343a.571.571,0,0,0,0,1.143Zm-.711-9.018,4.034-2.982a.573.573,0,1,0-.585-.987l-4.034,2.982a.573.573,0,1,0,.585.987Zm-.585,17.879,4.034,2.982a.573.573,0,1,0,.585-.987l-4.034-2.982a.573.573,0,1,0-.585.987Z' transform='translate(-37.944 -209.486)' fill='#ff725e' />
        <path id='Path_5857' data-name='Path 5857' d='M79.82,531.85l-1.307,8.042-.261,1.608-.043.263c-.028.174-.089.244-.218.285-.022.007-.2-.017-.059.01l-.094-.018-2.073-.4-.609-.119a.3.3,0,0,1-.263-.226c-.007-.065.018-.23-.008-.073l.006-.039,1.374-8.456.237-1.456-.505.421h2.148l-.524-.573v.024a.582.582,0,0,0,.385.553l2.181.864a.522.522,0,0,0,.645-.4.593.593,0,0,0-.366-.705l-2.181-.864.385.553v-.024a.555.555,0,0,0-.524-.573H76a.532.532,0,0,0-.505.421l-1.308,8.046-.265,1.629-.045.279a1.485,1.485,0,0,0,.811,1.641,2.949,2.949,0,0,0,.645.154l1.134.221a3.258,3.258,0,0,0,2.11.089,1.725,1.725,0,0,0,.721-1.411l.295-1.815.834-5.134.407-2.507a.594.594,0,0,0-.366-.705A.522.522,0,0,0,79.82,531.85Z' transform='translate(-70.759 -511.178)' fill='#ff725e' />
    </svg>
}

Megaphone.defaultProps = defaultProps

export { Megaphone }