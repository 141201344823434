import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQImage = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '41.999',
    height: '42.001',
    viewBox: '0 0 41.999 42.001',
}

const Image: PQImage = (props: Props) => {
    return <svg {...props} className={`pq-image ${props.className ? props.className : ''}`}>
        <path id='Union_66' data-name='Union 66' d='M-603-7074a21.024,21.024,0,0,1,21-21,21.024,21.024,0,0,1,21,21,21.023,21.023,0,0,1-21,21A21.023,21.023,0,0,1-603-7074Zm1.4,0a19.622,19.622,0,0,0,19.6,19.6,19.621,19.621,0,0,0,19.6-19.6,19.622,19.622,0,0,0-19.6-19.6A19.623,19.623,0,0,0-601.6-7074Zm10.305,10.519a2.1,2.1,0,0,1-2.1-2.094v-16.852a2.1,2.1,0,0,1,2.1-2.094h18.589a2.1,2.1,0,0,1,2.1,2.094v16.852a2.1,2.1,0,0,1-2.1,2.094Zm-.694-8.216v6.122a.694.694,0,0,0,.694.693h18.589a.694.694,0,0,0,.694-.693v-7.932l-4.5-4.5-11.619,11.618a.694.694,0,0,1-.495.206.692.692,0,0,1-.495-.206.7.7,0,0,1,0-.989l5.2-5.2-3.592-3.591Zm4.971-5.96,4.086,4.086,5.923-5.925a.7.7,0,0,1,.991,0l4.008,4.01v-6.94a.7.7,0,0,0-.694-.693h-18.59a.7.7,0,0,0-.694.693v8.749l3.981-3.979a.7.7,0,0,1,.5-.206A.693.693,0,0,1-587.019-7077.656Zm2.335-2.394a1.752,1.752,0,0,1,1.75-1.75,1.752,1.752,0,0,1,1.75,1.75,1.752,1.752,0,0,1-1.75,1.75A1.752,1.752,0,0,1-584.684-7080.05Zm1.4,0a.351.351,0,0,0,.35.351.351.351,0,0,0,.35-.351.351.351,0,0,0-.35-.35A.351.351,0,0,0-583.284-7080.05Z' transform='translate(603 7095.001)' fill='#ff725e' />
    </svg>
}

Image.defaultProps = defaultProps

export { Image }