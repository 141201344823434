import React from 'react'
import useDimension from '../hooks/useDimension'
import ShortDescription from './ShortDescription'
import { Graph, Megaphone, Target, Users } from './svg'

interface Props { }

type PQDescription = React.FC<Props>

const Description: PQDescription = (props) => {
    const { width } = useDimension()
    const [sectionIndex, setSectionIndex] = React.useState<number>(0)

    const sectionList = [
        {
            Icon: Users,
            description: <React.Fragment>Lower cost of customer satisfaction &amp; retention</React.Fragment>
        },
        {
            Icon: Target,
            description: <React.Fragment>Easier to fix targets &amp; accomplish goals</React.Fragment>
        },
        {
            Icon: Graph,
            description: <React.Fragment>Studies show that companies that are design-aware can outperform those that aren't by almost 200%</React.Fragment>
        },
        {
            Icon: Megaphone,
            description: <React.Fragment>Growth through word of mouth referrals</React.Fragment>
        }
    ]

    const listClickHandler: React.MouseEventHandler<HTMLDivElement> = () => {
        setSectionIndex(index => {
            return index === sectionList.length - 1 ? 0 : index + 1
        })
    }

    return <div className={`${width < 768 ? 'mt-50px' : 'mt-129px'}`}>
        <div className={`text-uppercase text-center ff-dax-pro text-dark-blue fw-regular ${width < 768 ? 'fs-14px' : 'fs-18px'}`}>{width < 768 ? 'WHY SHOULD YOU THINK ABOUT DESIGN?' : <React.Fragment>FRANK CHIMERO ONCE SAID, <i>'PEOPLE IGNORE DESIGNS THAT IGNORE PEOPLE'.</i> HERE'S A FEW REASONS WHY YOU SHOULD THINK ABOUT DESIGN:</React.Fragment>}</div>
        <div className='mt-20px'>
            <div className='container-fluid'>
                <div className='row'>
                    {width < 768
                        ? <React.Fragment>
                            <div className='d-flex align-items-center justify-content-center'>
                                <SectionDescription {...sectionList[sectionIndex]} onClick={listClickHandler} />
                            </div>
                            <SectionDescriptionDots
                                activeIndex={sectionIndex}
                                dotCount={sectionList.length}
                                onChange={(index) => setSectionIndex(index)}
                            />
                        </React.Fragment>
                        : sectionList.map((item, index) => {
                            return <SectionDescription {...item} key={index} />
                        })
                    }
                </div>
            </div>
        </div>
    </div>
}

interface SectionDescriptionProps {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>
    description: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const SectionDescription = (props: SectionDescriptionProps) => {
    return <div className='col-md-6 col-xl-3 cursor-pointer' onClick={props.onClick}>
        <ShortDescription className='my-10px' Icon={props.Icon}>{props.description}</ShortDescription>
    </div>
}

interface SectionDescriptionDotsProps {
    activeIndex?: number
    dotCount?: number
    onChange?: (index: number) => void
}

const SectionDescriptionDots = (props: SectionDescriptionDotsProps) => {
    return <div className='hstack gap-3 justify-content-center mt-10px'>
        {[...new Array(props.dotCount)].map((_, index) => {
            const dotClickHandler: React.MouseEventHandler<HTMLDivElement> = () => {
                if (typeof props.onChange === 'function') {
                    props.onChange(index)
                }
            }

            return <Dot key={index} onClick={dotClickHandler} active={props.activeIndex === index} />
        })}
    </div>
}

interface DotProps {
    active?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Dot = (props: DotProps) => {
    return <div className={`w-10px h-10px br-10px border border-dark-blue bg-${props.active ? 'dark-blue' : 'white'}`} onClick={props.onClick} />
}

export default Description