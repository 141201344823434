import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQArrowRight = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '48',
    height: '48',
    viewBox: '0 0 24 24',
    fill: 'none',
    stroke: '#FF725E',
    strokeWidth: '2',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
}

const ArrowRight: PQArrowRight = (props: Props) => {
    return <svg {...props} className={`pq-arrow-right ${props.className ? props.className : ''}`}>
        <line x1='5' y1='12' x2='19' y2='12'></line>
        <polyline points='12 5 19 12 12 19'></polyline>
    </svg>
}

ArrowRight.defaultProps = defaultProps

export { ArrowRight }
