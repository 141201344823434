import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQTarget = React.FC<Props>

const defaultProps: Props = {
    xmlns: 'http://www.w3.org/2000/svg',
    width: '27',
    height: '32',
    viewBox: '0 0 27 32'
}

const Target: PQTarget = (props: Props) => {
    return <svg {...props} className={`pq-target ${props.className ? props.className : ''}`}>
        <path id='Path_5853' data-name='Path 5853' d='M14.369,1.058A10.731,10.731,0,0,0,9.061,21.2L5.137,32.28a.566.566,0,0,0,.1.575.58.58,0,0,0,.993-.2L7.979,27.7l5.816-2.4v2.579a.57.57,0,0,0,.285.505.583.583,0,0,0,.585,0,.57.57,0,0,0,.285-.505V25.307l5.815,2.4,1.752,4.953a.58.58,0,0,0,.993.2.566.566,0,0,0,.1-.575L19.682,21.195a10.739,10.739,0,0,0,5.564-9.379,10.6,10.6,0,0,0-.407-2.88,1.7,1.7,0,0,1-.207-.071l-.969-.474-.177.1a9.5,9.5,0,0,1,.606,3.321A9.723,9.723,0,1,1,14.369,2.2a9.736,9.736,0,0,1,7.362,3.34l.027-.016.188-1.393c0-.006,0-.011,0-.017a10.9,10.9,0,0,0-7.582-3.057Zm13.675.235h-.059a.582.582,0,0,0-.251.076L23.376,3.858a.571.571,0,0,0-.285.422l-.263,1.946-7.3,4.277a1.778,1.778,0,0,0-1.161-.433,1.747,1.747,0,1,0,1.766,1.748,1.728,1.728,0,0,0-.032-.325l7.507-4.4,1.531.748a.583.583,0,0,0,.546-.017l4.517-2.58a.568.568,0,0,0-.069-1.02l-1.647-.67L28.6,1.9a.567.567,0,0,0-.148-.42.58.58,0,0,0-.408-.188Zm-.667,1.594-.043.695L24.1,5.427l.1-.723ZM14.369,3.8a8.02,8.02,0,1,0,8.108,8.017,7.9,7.9,0,0,0-.406-2.493l-1.022.6a6.815,6.815,0,0,1,.273,1.894,6.953,6.953,0,1,1-6.953-6.875A6.97,6.97,0,0,1,19.3,6.965l1.007-.59A8.121,8.121,0,0,0,14.369,3.8Zm13.564.76.679.277L25.378,6.682,24.8,6.4ZM14.369,6.665a5.153,5.153,0,1,0,5.21,5.15,5.082,5.082,0,0,0-.1-.975l-1.075.63c.01.114.018.228.018.345a4.056,4.056,0,1,1-4.056-4.008,4.078,4.078,0,0,1,2.3.7l1.063-.623A5.221,5.221,0,0,0,14.369,6.665Zm4.273,15.042L20.25,26.25l-5.3-2.183V22.544a10.908,10.908,0,0,0,3.694-.838Zm-8.543,0a10.911,10.911,0,0,0,3.7.836v1.521l-5.3,2.185Z' transform='translate(-3.492 -1.058)' fill='#ff725e' />
    </svg>
}

Target.defaultProps = defaultProps

export { Target }