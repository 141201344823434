import React from 'react'
import Bullet from './Bullet'

interface Props {
    children?: React.ReactNode
}

type PQBulletText = React.FC<Props>

const BulletText: PQBulletText = (props) => {
    return <div className='hstack my-5px align-items-start'>
        <div className='h-20px d-flex align-items-center'>
            <Bullet />
        </div>
        <span className='ms-6px d-inline-block text-dark-blue fs-14px ff-noto-sans fw-regular'>
            {props.children}
        </span>
    </div>
}

export default BulletText