import React from 'react'
import api, { APIResponse } from '../../config/api'
import { isValidEmail } from '../../config/functions'
import { ToastTypes, useToast } from '../toast/ToastProvider'

export interface AppContextType {
    bottomElement: React.RefObject<HTMLSpanElement>
    mailFormNameInput: React.RefObject<HTMLInputElement>
    registerInterestClickHandler: React.MouseEventHandler<HTMLButtonElement>
    subscribeEmail: string
    subscribeEmailError: string
    subscribing: boolean
    subscribeEmailChangeEventHandler: React.ChangeEventHandler<HTMLInputElement>
    subscribeClickEventHandler: React.MouseEventHandler<HTMLButtonElement>
    setSubscribeEmail: React.Dispatch<React.SetStateAction<string>>
    setSubscribeEmailError: React.Dispatch<React.SetStateAction<string>>
    setSubscribing: React.Dispatch<React.SetStateAction<boolean>>
}

interface SubscribeNewsletterData {
    email: string
}

interface Props {
    children?: React.ReactNode
}

export const AppContext = React.createContext<AppContextType | null>(null)

const AppProvider = (props: Props) => {
    const toast = useToast()

    const bottomElement = React.useRef<HTMLSpanElement>(null)
    const mailFormNameInput = React.useRef<HTMLInputElement>(null)

    const [subscribeEmail, setSubscribeEmail] = React.useState<string>('')
    const [subscribeEmailError, setSubscribeEmailError] = React.useState<string>('')

    const [subscribing, setSubscribing] = React.useState<boolean>(false)

    const subscribeEmailChangeEventHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSubscribeEmail(e.target.value)
        setSubscribeEmailError('')
    }

    const registerInterestClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        if (bottomElement.current !== null) {
            bottomElement.current.focus()
        }

        if (mailFormNameInput.current !== null) {
            mailFormNameInput.current.focus()
        }
    }

    const subscribeClickEventHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        let error = false
        const data: SubscribeNewsletterData = {
            email: subscribeEmail
        }

        if (data.email === '') {
            setSubscribeEmailError('Email required')
            error = true
        } else if (!isValidEmail(data.email)) {
            setSubscribeEmailError('Invalid email')
            error = true
        }

        if (!error) {
            setSubscribing(true)
            api.post<APIResponse>('subscribe_newsletter', data).then(response => {
                if (response.status === 200) {
                    toast(response.data.message ? response.data.message : 'Newsletter subscribed')
                    setSubscribeEmail('')
                    setSubscribeEmailError('')
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(error => {
                const errorText = error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to subscribe newsletter'
                    : error.message

                toast(errorText, ToastTypes.ERROR)
            }).finally(() => {
                setSubscribing(false)
            })
        }
    }

    return <AppContext.Provider {...props} value={{ bottomElement, registerInterestClickHandler, mailFormNameInput, subscribeClickEventHandler, subscribeEmail, subscribeEmailChangeEventHandler, subscribeEmailError, subscribing, setSubscribeEmail, setSubscribeEmailError, setSubscribing }} />
}

export default AppProvider