import React from 'react'
import Spinner from './Spinner'

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> { }
interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> { }

interface Props {
    inputProps?: InputProps
    buttonProps?: ButtonProps
    loading?: boolean
    errorText?: string
}

type PQInputGroup = React.FC<Props>

const InputGroup: PQInputGroup = (props) => {
    return <div className='vstack gap-1'>
        <div className='pq-input-group hstack'>
            <input {...props.inputProps} className={`${props.errorText && props.errorText !== '' ? 'error' : ''} ${props.inputProps?.className ? props.inputProps?.className : ''}`} />
            {props.loading
                ? <button {...props.buttonProps} onClick={e => e.preventDefault()}>
                    <Spinner />
                </button>
                : <button {...props.buttonProps} />}
        </div>
        <span className='d-inline-block text-orange fs-12px'>{props.errorText}</span>
    </div>
}

export default InputGroup