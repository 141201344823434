import React from 'react'
import KnackboxLg from '../assets/images/knackbox-lg.png'
import KnackboxMobile from '../assets/images/knackbox-mobile.png'
import MitatLg from '../assets/images/mitat-lg.png'
import MitatMobile from '../assets/images/mitat-mobile.png'
import useDimension from '../hooks/useDimension'
import ProjectContainer from './ProjectContainer'
import SectionHeading from './SectionHeading'

interface Props { }

type PQProjects = React.FC<Props>

const Projects: PQProjects = (props) => {
    const { width } = useDimension()

    return <React.Fragment>
        <SectionHeading>HERE'S A LOOK AT SOME OF OUR PROJECTS!</SectionHeading>
        <div className='row'>
            <div className='col-lg-6'>
                <ProjectContainer
                    to='/project/mitat'
                    img={width < 575 ? MitatMobile : MitatLg}
                    slideUpText={<React.Fragment>MITAT<br />To track kilometers and get returns on tax</React.Fragment>}
                    title='Mitat'
                    subTitle='To track kilometers and get returns on tax'
                />
            </div>
            <div className='col-lg-6'>
                <ProjectContainer
                    to='/project/knackbox'
                    img={width < 575 ? KnackboxMobile : KnackboxLg}
                    slideUpText={<React.Fragment>KNACKBOX<br />For children, to form and track new habits</React.Fragment>}
                    title='Knackbox'
                    subTitle='For children, to form and track new habits'
                />
            </div>
        </div>
    </React.Fragment>
}

export default Projects