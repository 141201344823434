import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> { }

type PQBoxContainer = React.FC<Props>

const BoxContainer: PQBoxContainer = (props) => {
    const { width } = useDimension()

    return <div {...props} className={`w-100 h-100 border border-1 border-dark-blue br-12px ${width < 768 ? 'px-20px py-24px' : 'p-28px'} ${props.className ? props.className : ''}`} />
}

export default BoxContainer