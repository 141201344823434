import axios, { AxiosRequestConfig } from 'axios'
import config from '.'

export interface APIResponse<D = undefined> {
    data?: D
    message?: string
    error?: string
}

const api = axios.create({ baseURL: config.apiUrl })

export const getAxiosRequestConfig = (token: string): AxiosRequestConfig => {
    return {
        headers: {
            Authorization: token
        }
    }
}

export default api