import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    expanded?: boolean
    onCardClick?: React.MouseEventHandler<HTMLDivElement>
}

type PQCard = React.FC<Props>

const Card: PQCard = (props) => {
    const [expandable, setExpandable] = React.useState<boolean>(false)
    const elem = React.useRef<HTMLDivElement>(null)
    const { width } = useDimension()

    const maxHeight = elem.current ? elem.current.scrollHeight : 0

    React.useEffect(() => {
        setExpandable(width < 768)
    }, [width])

    return <div {...props} className={`pq-card overflow-hidden ${expandable ? (props.expanded ? `expand-${maxHeight}px` : 'pq-collapse') : ''} ${props.className ? props.className : ''}`} onClick={props.onCardClick} ref={elem} />
}

export default Card