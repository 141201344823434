import React from 'react'
import useDimension from '../hooks/useDimension'

interface Props {
    children?: React.ReactNode
}

type PQFooter = React.FC<Props>

const Footer: PQFooter = (props) => {
    const { width } = useDimension()

    return <div className='bg-blue py-60px'>
        <div className={`container ${width < 768 ? 'px-32px' : ''}`}>{props.children}</div>
    </div>
}

export default Footer