import React from 'react'
import api, { APIResponse } from '../config/api'
import { isValidEmail } from '../config/functions'
import useDimension from '../hooks/useDimension'
import Button from './Button'
import { AppContext, AppContextType } from './context/AppProvider'
import Footer from './Footer'
import Input from './Input'
import SubscribeDescription from './SubscribeDescription'
import SubscribeSubHeading from './SubscribeSubHeading'
import TextArea from './TextArea'
import { ToastTypes, useToast } from './toast/ToastProvider'

interface SendMailData {
    name: string
    email: string
    companyName: string
    address: string
    heading: string
    description: string
}

const SubscribeContainer = () => {
    const width = useDimension().width
    const toast = useToast()

    const { mailFormNameInput, bottomElement, subscribeClickEventHandler, subscribeEmail, subscribeEmailChangeEventHandler, subscribeEmailError, subscribing } = React.useContext<AppContextType | null>(AppContext)!

    const [name, setName] = React.useState<string>('')
    const [nameError, setNameError] = React.useState<string>('')

    const [email, setEmail] = React.useState<string>('')
    const [emailError, setEmailError] = React.useState<string>('')

    const [address, setAddress] = React.useState<string>('')
    const [addressError, setAddressError] = React.useState<string>('')

    const [companyName, setCompanyName] = React.useState<string>('')
    const [companyNameError, setCompanyNameError] = React.useState<string>('')

    const [heading, setHeading] = React.useState<string>('')
    const [headingError, setHeadingError] = React.useState<string>('')

    const [description, setDescription] = React.useState<string>('')
    const [descriptionError, setDescriptionError] = React.useState<string>('')

    const [sending, setSending] = React.useState<boolean>(false)

    const nameChangeEventHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const emailChangeEventHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    const addressChangeEventHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress(e.target.value)
        setAddressError('')
    }

    const companyNameChangeEventHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setCompanyName(e.target.value)
        setCompanyNameError('')
    }

    const headingChangeEventHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setHeading(e.target.value)
        setHeadingError('')
    }

    const descriptionChangeEventHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setDescription(e.target.value)
        setDescriptionError('')
    }

    const sendClickEventHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        let error = false
        const data: SendMailData = {
            address: address,
            companyName: companyName,
            description: description,
            email: email,
            heading: heading,
            name: name
        }

        if (data.name === '') {
            setNameError('Name required')
            error = true
        }

        if (data.email === '') {
            setEmailError('Email required')
            error = true
        } else if (!isValidEmail(data.email)) {
            setEmailError('Invalid email')
            error = true
        }

        if (data.heading === '') {
            setHeadingError('Heading required')
            error = true
        }

        if (data.description === '') {
            setDescriptionError('Description required')
            error = true
        }

        if (!error) {
            setSending(true)
            api.post<APIResponse>('send_mail', data).then(response => {
                if (response.status === 200) {
                    toast(response.data.message ? response.data.message : 'Mail sent')
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }
            }).catch(error => {
                const errorText = error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to send mail'
                    : error.message

                toast(errorText, ToastTypes.ERROR)
            }).finally(() => {
                setSending(false)
            })
        }
    }

    return <Footer>
        <div className='row'>
            <div className='col-lg-4'>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-lg-12'>
                        <SubscribeSubHeading href='mailto:pragmiqoy@gmail.com' className='mb-16px'>START A PROJECT</SubscribeSubHeading>
                        <SubscribeDescription className={`${width < 768 ? 'mb-40px' : 'mb-60px'}`}>We are ready for the challenge!<br />Contact us at: pragmiqoy@pragmiq.com</SubscribeDescription>
                    </div>

                    <div className='col-12 col-sm-6 col-lg-12'>
                        <SubscribeSubHeading href='mailto:pragmiqoy@gmail.com' className='mb-16px'>JOBS</SubscribeSubHeading>
                        <SubscribeDescription className={`${width < 768 ? 'mb-40px' : 'mb-60px'}`}>We are always looking for talent<br />pragmiqoy@pragmiq.com</SubscribeDescription>
                    </div>
                </div>

                <SubscribeSubHeading href='/' className='mb-16px' onClick={e => e.preventDefault()}>SUBSCRIBE TO OUR NEWSLETTER</SubscribeSubHeading>
                <SubscribeDescription className='mb-10px'>Stay tuned with the latest news &amp; updates!</SubscribeDescription>

                <Input
                    placeholder='Email Address'
                    containerClass='mb-12px'
                    onChange={subscribeEmailChangeEventHandler}
                    value={subscribeEmail}
                    errorText={subscribeEmailError}
                />
                <Button className='w-100 mb-12px' onClick={subscribeClickEventHandler} loading={subscribing}>SUBSCRIBE!</Button>
            </div>
            <div className='col-lg-8'>
                <div className='br-12px py-40px px-35px bg-white'>
                    <div className={`ff-dax-pro text-dark-blue mb-16px fw-regular ${width < 768 ? 'fs-16px' : 'fs-21px'}`}>Let's get in touch!</div>
                    <div className='ff-noto-sans fw-regular fs-14px text-extra-dark-gray mb-24px'>Feel free to say hi, and we can begin discussing your project!</div>

                    <div className='row'>
                        <div className='col-md-6 mb-24px'>
                            <Input
                                placeholder='Name*'
                                onChange={nameChangeEventHandler}
                                value={name}
                                errorText={nameError}
                                innerRef={mailFormNameInput}
                            />
                        </div>
                        <div className='col-md-6 mb-24px'>
                            <Input
                                placeholder='Email*'
                                onChange={emailChangeEventHandler}
                                value={email}
                                errorText={emailError}
                            />
                        </div>

                        <div className='col-md-6 mb-24px'>
                            <Input
                                placeholder='Company name (Optional)'
                                onChange={companyNameChangeEventHandler}
                                value={companyName}
                                errorText={companyNameError}
                            />
                        </div>
                        <div className='col-md-6 mb-24px'>
                            <Input
                                placeholder='Address (Optional)'
                                onChange={addressChangeEventHandler}
                                value={address}
                                errorText={addressError}
                            />
                        </div>

                        <div className='col-12 mb-24px'>
                            <Input
                                placeholder='Heading'
                                onChange={headingChangeEventHandler}
                                value={heading}
                                errorText={headingError}
                            />
                        </div>

                        <div className='col-12 mb-24px'>
                            <TextArea
                                placeholder='I am interested! Please contact me on my mail ID.'
                                onChange={descriptionChangeEventHandler}
                                value={description}
                                errorText={descriptionError}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className='text-end'>
                                <Button className={width < 768 ? 'w-100' : 'w-270px'} onClick={sendClickEventHandler} loading={sending}>SEND</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span ref={bottomElement} />
    </Footer>
}

export default SubscribeContainer