import { useCallback, useEffect, useState } from 'react'

interface Dimension {
    width: number
    height: number
}

export const getDimension = <T extends HTMLElement | SVGElement>(element?: React.RefObject<T>) => {
    let width: number = 0
    let height: number = 0

    if (element !== undefined && element.current !== null) {
        width = element.current.clientWidth
        height = element.current.clientHeight
    } else {
        width = window.innerWidth
        height = window.innerHeight
    }

    return { width, height }
}

const useDimension = <T extends HTMLElement | SVGElement>(element?: React.RefObject<T>) => {
    const [dimension, setDimension] = useState<Dimension>(getDimension<T>(element))

    const handleResize = useCallback(() => {
        setDimension(getDimension<T>(element))
    }, [element])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [handleResize])

    return dimension
}

export default useDimension