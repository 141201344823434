import React from 'react'
import useDimension from '../hooks/useDimension'
import Card from './Card'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>
    heading: string
    expanded?: boolean
    onCardClick?: React.MouseEventHandler<HTMLDivElement>
}

type PQOurServicesContainer = React.FC<Props>

const OurServicesContainer: PQOurServicesContainer = ({ heading, Icon, ...props }) => {
    const { width } = useDimension()

    return <Card {...props} className='h-100' expanded={props.expanded} onCardClick={props.onCardClick}>
        <div className={`${width < 768 ? 'hstack gap-2 mb-16px' : ''}`}>
            <Icon />
            <div className={`text-dark-blue fw-500 ff-dax-pro ${width < 768 ? 'fs-16px' : 'fs-20px mt-20px mb-24px'}`}>{heading}</div>
        </div>
        {props.children}
    </Card>
}

export default OurServicesContainer