import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> { }

type PQBox = React.FC<Props>

const defaultProps: Props = {
    id: 'Group_548',
    xmlns: 'http://www.w3.org/2000/svg',
    width: '42',
    height: '46',
    viewBox: '0 0 42 46',
}

const Box: PQBox = (props: Props) => {
    return <svg {...props} data-name='Group 548' className={`pq-box ${props.className ? props.className : ''}`}>
        <path id='Subtraction_4' data-name='Subtraction 4' d='M13.165,44.508.763,38.673a1.585,1.585,0,0,1-.742-1.49C.039,36.145.005,11.436,0,8.132q0-.037,0-.076c0-.188,0-.288,0-.288l.038.018c.218-.845,1.29-1.134,1.3-1.137L22.2.582A14.454,14.454,0,0,1,25.947,0a9.73,9.73,0,0,1,4.545,1.252l.014.006c.213.1.435.2.662.309L41.2,6.872a1.208,1.208,0,0,1,.8,1.12,1.159,1.159,0,0,1-.017.2L42,8.184S41.993,34.8,42,36.349s-1,1.806-1,1.806L26.083,44.264C22.425,45.424,20.518,46,18.791,46,17.182,46,15.729,45.5,13.165,44.508Zm9.275-43.2C21.805,1.49,6.857,5.774,3.625,6.78c-1.5.466-2.174.836-2.193,1.2-.021.4.72.774,2.094,1.333.406.165,1.061.441,1.82.76,2.718,1.143,7.267,3.056,10.4,4.162a9.964,9.964,0,0,0,3.5.789,14.036,14.036,0,0,0,3.866-.709c4.411-1.28,10.929-3.469,14.431-4.644l.038-.013c.871-.292,1.558-.523,1.926-.643.939-.306,1.41-.6,1.44-.911.039-.4-.707-.786-1.366-1.122-1.134-.58-7.708-4.071-9.014-4.764L30.527,2.2c-.208-.1-.411-.191-.607-.284A8.772,8.772,0,0,0,25.82.784,13.04,13.04,0,0,0,22.44,1.309Z' fill='#ffd15a' />
    </svg>
}

Box.defaultProps = defaultProps

export { Box }