import React from 'react'
import useDimension from '../hooks/useDimension'
import BoxContainer from './BoxContainer'

interface Props {
    heading?: string
    children?: React.ReactNode
}

type PQProjectDescriptionBox = React.FC<Props>

const ProjectDescriptionBox: PQProjectDescriptionBox = (props) => {
    const { width } = useDimension()

    return <BoxContainer>
        <div className={`ff-dax-pro fw-medium text-orange text-uppercase mb-28px ${width ? 'fs-16px' : 'fs-20px'}`}>{props.heading}</div>
        {props.children}
    </BoxContainer>
}

export default ProjectDescriptionBox